import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Modal.css';

type Props = {
    isOpen : boolean;
    modalTitle : string;
    modalBody ?: any;
    toggle ?:any;
    id : string;
    titleData ?: any;
}
export const ModalComp: React.FC<Props> = ({ isOpen , modalTitle,modalBody,toggle,id,titleData}: Props) => {
  const [modal, setModal] = useState(false);
  return (
    <div>
      <Modal isOpen={isOpen}  centered={true} id={id} toggle={toggle}>
        <ModalHeader toggle={toggle} style={{width: '100%'}}>{modalTitle === 'worklogTitle' ?
        <div style={{fontSize: 'medium',width: '100%'}}>
          <b>Worklog Details : </b><span>{titleData.name}</span>
          <span> - {titleData.date}</span>
          <span  style={{marginRight: '20px',float: 'right' }}>Total: <span style={{ color: parseInt(titleData.total) < 10 ? 'green' : 'red'}}>{titleData.total}hrs</span></span>
        </div> :
        modalTitle
      }</ModalHeader>
        <ModalBody>
         {modalBody}
        </ModalBody>
        {/* <ModalFooter>
        <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={toggle}>
             Save
          </Button>{' '}
        </ModalFooter> */}
      </Modal>
    </div>
  );
}
