import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Row, Col } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addProject, editProject, fetchUser, fetchVerifiedTeamLeadUser, fetchVerifiedUser } from '../store/UserAction';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './CreateProject.css'
import moment from 'moment';

type Props = {
  formType : string;
  rowData ?: any;
  addTableData ?: any;
  closeModal ?: any;
}
type FormDataProps ={
  id ?: any;
  name ?: any;
  projectLead ?: any;
  teamMembers?: any;
  allocatedHrs ?: any;
  startDate ?: any;
  endDate ?: any;
}
type TableDataProps ={
  id ?: any;
  name ?: any;
  allocatedHrs ?: any;
}

export const CreateProject: React.FC<Props>  = ({formType,rowData,addTableData, closeModal}: Props) => {
const [data, setData] =useState<FormDataProps>(rowData);
const [projectNameValid, setValidProjectName] = useState(false);
const [projectLeadValid, setValidProjectLead] = useState(false)
const [projectMemberValid, setValidProjectMember] = useState(false)
const getVerifiedUsers = useAppSelector(state=>state.user.getVerifiedUsers);
const user = useAppSelector(state=>state.user.verifiedUsers)
const dispatch=useAppDispatch()
const loading = useAppSelector(state=>state.user.loading);
const [isOpenProject,setIsOpenProject] = useState(false)
const [isOpenUser,setIsOpenUser] = useState(false)
const [employeeData, setEmployeeData] = useState([])
const [teamMemberData, setTeamMemberData] = useState([])
const [showTeamMemberTable, setTeamMemberTable] = useState(false)
const [projectLead,setProjectLead] = useState([])
const [showInputErr, setShowInputErr] = useState(false)
const [errMsg, setErrMsg] = useState('')
const [allocatedHrs ,setAllocatedHrs] = useState(null)
const getVerifiedTeamLeadUsers = useAppSelector(state=>state.user.getVerifiedTeamLeadUsers);
const userForTeamLead = useAppSelector(state=>state.user.verifiedTeamLeadUsers)
const [userList, setUserList] = useState([])
const [tableData, setTableData] = useState([]);
const [teamMembers, setTeamMembers] = useState([])

useEffect(() =>{
  if(getVerifiedUsers){
   setUserList(user)
  }
  else if(getVerifiedTeamLeadUsers){
    setUserList(userForTeamLead)
  }
  if(JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead'){
    dispatch<any>(fetchVerifiedTeamLeadUser());
    setUserList(userForTeamLead)
  }
  else if(JSON.parse(localStorage.getItem('user'))?.role === 'admin'){
      dispatch<any>(fetchVerifiedUser());
      setUserList(user)
  }
},[dispatch,getVerifiedUsers,getVerifiedTeamLeadUsers])

const onSubmit = (e) =>{
  e.preventDefault()
  let flag = false

  const totalAllocatedHrs = tableData.reduce((sum, entry) => {
    const hours = parseInt(entry.allocatedHrs, 10) || 0;
    return sum + hours;
  }, 0);
  if(parseInt(data.allocatedHrs) < totalAllocatedHrs){
    setShowInputErr(true)
    flag = true
    setErrMsg('Allocated hours for project should not be less than total allocated hours for team members')
   }
   else{
    setShowInputErr(false)
    flag = false
    setErrMsg('')
   }
  if(data?.name === undefined){
    flag = true
    setValidProjectName(true)
  } 
  if(data?.projectLead === undefined){
    flag = true
    setValidProjectLead(true)
  } 

  if(!flag){
      let Hrs = data.allocatedHrs !== undefined ? data.allocatedHrs.concat(':00') : ''
      let HrsFlag = data.allocatedHrs !== undefined ? data.allocatedHrs.includes(":00") : ''
      const finalProjectLead = data.projectLead
      ? {
          user: {
            id: data.projectLead.user.id,
          },
          allocatedHrs: `${tableData.find((item) => item.id === data.projectLead.id)?.allocatedHrs || "0"}:00`,
        }
      : null;
  
    const finalTeamMembers = teamMembers.filter((member) => member.id !== data.projectLead.id) 
            .map((member) => {
                const hours = tableData.find((item) => item.id === member.id)?.allocatedHrs || "0";
                return {
                  user: {
                    id: member.user.id,
                  },
                  allocatedHrs: `${hours}:00`,
                };
              });
    const finalObject = {
      ...data,
      projectLead: finalProjectLead,
      teamMembers: finalTeamMembers,
    };
       dispatch<any>(addProject(finalObject));
  }

}
const handleLeadClick = (e) =>{
  if (e.length > 0) {
    const lead = e[0];
    let obj = {
      id: lead.id,
      name: lead.name,
      user: {id: lead.id}
  }
    setTeamMembers((prev) => {
      const isLeadInTeam = prev.some((member) => member.id === lead.id);
      if (!isLeadInTeam) {
        return [
          ...prev,
          {
            id: lead.id,
            name: lead.name,
            user: { id: lead.id },
            role: "Project Lead",
          },
        ];
      }
      return prev; // No changes if lead is already in the team
    });
    setUserList((prevList) =>
      prevList.filter((user) => user.id !== lead.id)
    );
    setData({ ...data, projectLead: obj });
    setTableData((prev) => [
      { id: lead.id, name: lead.name, allocatedHrs: '' },
      ...prev.filter((item) => item.id !== lead.id),
    ]);
    setTeamMemberTable(true)
  } else {
    setData({ ...data, projectLead: null });
    setUserList(user)
    setTeamMembers((prev) => prev.filter((member) => member.id !== data.projectLead?.id))
    setTableData((prev) => prev.filter((item) => item.id !== data.projectLead?.id));
  }
}

const handleHoursChange = (id, value) => {
  setTableData((prev) => {
    const updatedTable = prev.map((entry) =>
      entry.id === id ? { ...entry, allocatedHrs: value } : entry
    );
    const totalAllocatedHrs = updatedTable.reduce((sum, entry) => {
      const hours = parseInt(entry.allocatedHrs, 10) || 0;
      return sum + hours;
    }, 0);
    if (parseInt(data.allocatedHrs) < totalAllocatedHrs) {
      setShowInputErr(true);
      setErrMsg('Total allocated hours cannot exceed project allocated hours.');
    } else {
      setShowInputErr(false);
      setErrMsg('');
    }
    return updatedTable;
  });
};
const handleTeamMemberClick = (e) =>{
  setData({ ...data, teamMembers: e });
  const selectedIds = e.map((member) => member.id);
  setIsOpenUser(false)
  setUserList((prevList) =>
    prevList.filter((user) => !e.some((sel) => sel.id === user.id))
  );
  
  setTableData((prev) => {
    const updatedTable = prev.filter((item) => selectedIds.includes(item.id));
    const newMembers = e.filter(
      (member) => !prev.some((item) => item.id === member.id)
    );
    return [
      ...updatedTable,
      ...newMembers.map((member) => ({ id: member.id, name: member.name, allocatedHrs: '' })),
    ];
  });
  setTeamMembers((prev) => {
    // Remove members from the table that are no longer selected
    const updatedTableData = prev.filter((item) => selectedIds.includes(item.id));

    // Add new members that were selected
    const newMembers = e.filter(
      (member) => !prev.some((item) => item.id === member.id)
    );

    const newRows = newMembers.map((member) => ({
      id: member.id,
      name: member.name,
      user:{id:member.id},
      allocatedHrs: "",  // Default empty allocated hours
      role: "Team Member",
    }));
    return [...updatedTableData, ...newRows];
  });
}
const handleProjectChange = (e) =>{
  if(e !== ''){
    setValidProjectName(false)
  }
  setData({ ...data, name : e.target.value }); 
}
const closeOutsideProject = () =>{
  setIsOpenProject(false)
}
const handleOpenProject = () =>{
  setIsOpenProject(true)
}
const closeOutsideUser = () =>{
  setIsOpenUser(false)
}
const handleOpenUser = () =>{
  setIsOpenUser(true)
}
const handleTime = (e) =>{
  let value = e.target.value;
  value = value.replace(/[^0-9:]/g, "");
  setData({ ...data, allocatedHrs : value }); 
  setAllocatedHrs(value)
}

const handleStartDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , startDate: stringDate})
  }
  else {
   setData({ ...data, startDate : null }); 
  }
}
const handleEndDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , endDate: stringDate})
  }
  else {
   setData({ ...data, endDate : null }); 
  }
}

const onChangeInput = (e, employeeId) => {
  const { name, value } = e.target
  let editData = []
   editData = teamMemberData.map((item) =>
   item.id === employeeId && name ? { ...item, [name]: value} : item
  )
  let totalHrs = editData.reduce((a,v) =>  a = parseInt(a) + parseInt(v.allocatedHrs) , 0 )
  setTeamMemberData(editData)
 setData({...data, teamMembers: editData})
 if(parseInt(data.allocatedHrs) < totalHrs){
  setErrMsg('Total of allocated hours for team members should not be greater than allocated hours')
  setShowInputErr(true)
 }
 else{
  setShowInputErr(false)
  setErrMsg('')
 }
}
return (
    <Fragment>
       <Form onSubmit={onSubmit}>
            <FormGroup>
                <Label for="exampleEmail">
                 Project Name
                </Label>
                <Input
                invalid = {projectNameValid}
                value={data?.name}
                id="exampleEmail"
                name="name"
                placeholder="Enter Project Name"
                type="text"
                onChange={handleProjectChange}
                />
                <FormFeedback>
                   Please enter project name
                </FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                 Allocated Hours
                </Label>
                <Input
                    type= "number"
                    value = {allocatedHrs}
                    onChange={handleTime}
                    placeholder="Enter Allocated Hours"
                  />
            </FormGroup>
            <div>
                <Row>
                    <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          Start Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.startDate && new Date((moment(data?.startDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleStartDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
                     <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          End Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.endDate && new Date((moment(data?.endDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleEndDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
            </Row>
            </div>
            <FormGroup>
                <Label for="examplePassword">
                 Project Lead
                </Label>
                <Typeahead
                  isInvalid={projectLeadValid}
                  id="basic-typeahead-single"
                  emptyLabel="No match found"
                  onFocus={handleOpenProject}
                  onBlur={closeOutsideProject}
                  open= {isOpenProject}
                  className='dropdownComp'
                  labelKey="name"
                  onChange={handleLeadClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  clearButton
                  placeholder="Select Project Lead"
                  selected={data.projectLead ? [data.projectLead] : []}
                />
                  <FormFeedback>
                    Please select project lead
                 </FormFeedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: isOpenUser ? '80px' : 0}}>
            <Label for="examplePassword">
                 Team Member
                </Label>
                <Typeahead
                  id="userDropdown"
                  onBlur= {closeOutsideUser}
                  onFocus={handleOpenUser}
                  open= {isOpenUser}
                  labelKey="name"
                  multiple
                  isInvalid={projectMemberValid}
                  onChange={handleTeamMemberClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  placeholder="Select Team Member"
                  selected={teamMembers}
                />
                <FormFeedback>
                   Please select team member
                </FormFeedback>
            </FormGroup>
            <FormGroup>
              {showTeamMemberTable && 
            <table className="inputTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Allocated Hours</th>
          </tr>
        </thead>
        <tbody>
        {tableData.map((entry) => (
                  <tr key={entry.id}>
                    <td>{entry.name}</td>
                    <td>
                      <Input
                        type="number"
                        value={entry.allocatedHrs}
                        onChange={(e) => handleHoursChange(entry.id, e.target.value)}
                        placeholder="Enter hours"
                      />
                    </td>
                  </tr>
                ))}
        </tbody>
      </table>
}
            </FormGroup>
            {showInputErr && 
            <div style={{color: 'red'}}>
              <li>{errMsg}</li>
              </div>}
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick={closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}
