import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Row, Col } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addProject, editProject, fetchUser, fetchVerifiedTeamLeadUser, fetchVerifiedUser } from '../store/UserAction';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './CreateProject.css'
import moment from 'moment';

type Props = {
  formType : string;
  rowData ?: any;
  addTableData ?: any;
  closeModal ?: any;
}
type FormDataProps ={
  id ?: any;
  name ?: any;
  projectLead ?: any;
  teamMembers?: any;
  allocatedHrs ?: any;
  startDate ?: any;
  endDate ?: any;
}
type TableDataProps ={
  id ?: any;
  name ?: any;
  allocatedHrs ?: any;
}

export const EditProject: React.FC<Props>  = ({formType,rowData,addTableData, closeModal}: Props) => {
const [data, setData] =useState<FormDataProps>(rowData);
const [projectNameValid, setValidProjectName] = useState(false);
const [projectLeadValid, setValidProjectLead] = useState(false)
const [projectMemberValid, setValidProjectMember] = useState(false)
const getVerifiedUsers = useAppSelector(state=>state.user.getVerifiedUsers);
const user = useAppSelector(state=>state.user.verifiedUsers)
const dispatch=useAppDispatch()
const loading = useAppSelector(state=>state.user.loading);
const [isOpenProject,setIsOpenProject] = useState(false)
const [isOpenUser,setIsOpenUser] = useState(false)
const [employeeData, setEmployeeData] = useState([])
const [teamMemberData, setTeamMemberData] = useState([])
const [showTeamMemberTable, setTeamMemberTable] = useState(false)
const [showInputErr, setShowInputErr] = useState(false)
const [errMsg, setErrMsg] = useState('')
const [allocatedHrs ,setAllocatedHrs] = useState(null)
const getVerifiedTeamLeadUsers = useAppSelector(state=>state.user.getVerifiedTeamLeadUsers);
const userForTeamLead = useAppSelector(state=>state.user.verifiedTeamLeadUsers)
const [userList, setUserList] = useState([])
const [projectLead, setProjectLead] = useState({
    id: data.projectLead.id,
    name: data.projectLead.user.name,
    user: {id: data.projectLead.user.id},
  });
const [teamMembers, setTeamMembers] = useState([
    {
        id: data.projectLead.id,
        name: data.projectLead.user.name,
        user: {id: data.projectLead.user.id},
        allocatedHrs: parseInt(data.projectLead.allocatedHrs.split(":")[0], 10),
      },
      ...data.teamMembers.map((member) => ({
        id: member.id,
        name: member.user.name,
        user:{id:member.user.id},
        allocatedHrs: parseInt(member.allocatedHrs.split(":")[0], 10),
      })),
]);
const [tableData, setTableData] = useState([
    {
      id: data.projectLead.id,
      name: data.projectLead.user.name,
      user: {id: data.projectLead.user.id},
      allocatedHrs: parseInt(data.projectLead.allocatedHrs.split(":")[0], 10),
    },
    ...data.teamMembers.map((member) => ({
      id: member.id,
      name: member.user.name,
      user:{id:member.user.id},
      allocatedHrs: parseInt(member.allocatedHrs.split(":")[0], 10),
    })),
]);

useEffect(() =>{
  if(getVerifiedUsers){
    if(data.teamMembers !== undefined){
        const newArray = [...data.teamMembers, data.projectLead];
        setTeamMemberTable(true)
        const updatedUserList =  newArray.reduce((prevList, item) => {
            return prevList.filter((user) => user.id !== item.user.id);
          }, user);
          setUserList(updatedUserList);
       
      }
      else{
        setUserList(user)
      }
  
  }
  else if(getVerifiedTeamLeadUsers){
    if(data.teamMembers !== undefined){
        const newArray = [...data.teamMembers, data.projectLead];
        setTeamMemberTable(true)
        const updatedUserList = newArray.reduce((prevList, item) => {
            return prevList.filter((user) => user.id !== item.user.id);
          }, user);
          setUserList(updatedUserList);
       
      }
      else{
        setUserList(userForTeamLead)
      }
  }

  if(data.projectLead !== undefined){
    let empArr = [...employeeData]
    empArr.push(data?.projectLead?.user)
    setEmployeeData(empArr)
  }
  if(data.allocatedHrs !== undefined){
      const parts = data.allocatedHrs.split(':');
      const mins = parseInt(parts[0], 10);
      setAllocatedHrs(mins)
  }
 
  if(JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead'){
    dispatch<any>(fetchVerifiedTeamLeadUser());
    if(data.teamMembers !== undefined){
        setTeamMemberTable(true)
        const newArray = [...data.teamMembers, data.projectLead];
        const updatedUserList = newArray.reduce((prevList, item) => {
            return prevList.filter((user) => user.id !== item.user.id);
          }, user);
          setUserList(updatedUserList);
       
      }
      else{
        setUserList(userForTeamLead)
      }
    }
  else if(JSON.parse(localStorage.getItem('user'))?.role === 'admin'){
      dispatch<any>(fetchVerifiedUser());
      if(data.teamMembers !== undefined){
        const newArray = [...data.teamMembers, data.projectLead];
        setTeamMemberTable(true)
        const updatedUserList = newArray.reduce((prevList, item) => {
            return prevList.filter((user) => user.id !== item.user.id);
          }, user);
          setUserList(updatedUserList);
        }
      else{
        setUserList(user)
      }
  }
},[dispatch,getVerifiedUsers,getVerifiedTeamLeadUsers])

const onSubmit = (e) =>{
  e.preventDefault()
  let flag = false
  const totalAllocatedHrs = tableData.reduce((sum, entry) => {
    const hours = parseInt(entry.allocatedHrs, 10) || 0;
    return sum + hours;
  }, 0);
  if(parseInt(data.allocatedHrs) < totalAllocatedHrs){
    setShowInputErr(true)
    flag = true
    setErrMsg('Allocated hours for project should not be less than total allocated hours for team members')
   }
   else{
    setShowInputErr(false)
    flag = false
    setErrMsg('')
   }
  if(data?.name === undefined){
    flag = true
    setValidProjectName(true)
  } 
  if(data?.projectLead === undefined){
    flag = true
    setValidProjectLead(true)
  } 
        // Format hours (e.g., "40" to "40:00")
        const formatHours = (hours) => {
            const numeric = parseInt(hours, 10);
            return `${numeric}:00`;
        };

  if(!flag){
    const finalProjectLead = projectLead
    ? {
        id: projectLead.id,
        user: {
          id: projectLead.user.id,
        },
        project:{
         id: data.id, 
        },
        allocatedHrs: `${tableData.find((item) => item.user.id === projectLead.user.id)?.allocatedHrs || "0"}:00`,
        loggedHrs: "00:00",
      }
    : null;

  const finalTeamMembers = teamMembers.map((member) => {
   const hours = tableData.find((item) => item.id === member.id)?.allocatedHrs || "0";
    return {
      id: member.id,
      user: {
        id: member.user.id,
        name: member.name,
        companyName: member.companyName,
      },
      allocatedHrs: `${hours}:00`,
      loggedHrs: "00:00",
    };
  });

  const finalObject = {
    ...data,
    projectLead: finalProjectLead,
    teamMembers: finalTeamMembers,
  };

   const transformedData = transformProjectsData(finalObject);
   dispatch<any>(editProject(transformedData));
  }

}
const transformProjectsData = (data) => {
    let Hrs = data.allocatedHrs.concat(':00')
    let HrsFlag = data.allocatedHrs.includes(":00")
    return {
      id: data.id,
      name: data.name,
      startDate: data.startDate,
      endDate: data.endDate,
      allocatedHrs : HrsFlag ? data.allocatedHrs : Hrs,
      projectLead: {
        id: data.projectLead.id,
        user: {
          id: data.projectLead.user.id,
        },
        project: {
          id: data.id, // Assign project id
        },
        allocatedHrs: data.projectLead.allocatedHrs,
      },
      teamMembers: data.teamMembers
        .filter((member) => member.user.id !== data.projectLead.user.id) // Exclude project lead
        .map((member) => ({
          id: member.id,
          user: {
            id: member.user.id,
          },
          project: {
            id: data.id, // Assign project id
          },
          allocatedHrs: member.allocatedHrs,
        })),
    };
  };
  
const handleLeadClick = (selected) =>{
    if (selected.length > 0) {
        const lead = selected[0];
        let obj = {
            id: lead.id,
            name: lead.name,
            user: {id: lead.id}
        }
       setProjectLead(obj);
         // Check if the selected lead is already in the team members list
       setTeamMembers((prev) => {
        const isAlreadyTeamMember = prev.some((member) => member.name === lead.name);
        if (!isAlreadyTeamMember) {
          return [
            ...prev,
            {
              id: lead.id,
              name: lead.name,
              user: { id: lead.id },
              role: "Project Lead",
            },
          ];
        }
        return prev; // If already a team member, no changes
      });
      // Update the table data to ensure the project lead appears in the table only once
      setTableData((prev) => {
        const isLeadInTable = prev.some((item) => item.name === lead.name);
        if (!isLeadInTable) {
          return [
            { id: lead.id,  user: { id: lead.id },name: lead.name, allocatedHrs: "" },
            ...prev,
          ];
        }
        return prev;
      });
    }
    else{
        let tableArr =  tableData.filter((data)=> data.name != projectLead.name)
        let teamArr =  teamMembers.filter((data)=> data.name != projectLead.name)
        setProjectLead(null)
        setTableData(tableArr)
        setUserList(user)
        setTeamMembers(teamArr)
    }
}
const handleTeamMemberClick = (selected) =>{
    const selectedIds = selected.map((member) => member.id);

    setTableData((prev) => {
      // Remove members from the table that are no longer selected
      const updatedTableData = prev.filter((item) => selectedIds.includes(item.id));

      // Add new members that were selected
      const newMembers = selected.filter(
        (member) => !prev.some((item) => item.id === member.id)
      );

      const newRows = newMembers.map((member) => ({
        id: member.id,
        name: member.name,
        user:{id:member.id},
        allocatedHrs: "",  // Default empty allocated hours
        role: "Team Member",
      }));
      return [...updatedTableData, ...newRows];
      
    });
  
    setIsOpenUser(false)
    setTeamMembers((prev) => {
        // Remove members from the table that are no longer selected
        const updatedTableData = prev.filter((item) => selectedIds.includes(item.id));
  
        // Add new members that were selected
        const newMembers = selected.filter(
          (member) => !prev.some((item) => item.id === member.id)
        );
  
        const newRows = newMembers.map((member) => ({
          id: member.id,
          name: member.name,
          user:{id:member.id},
          allocatedHrs: "",  // Default empty allocated hours
          role: "Team Member",
        }));
        return [...updatedTableData, ...newRows];
        
      });
    let arr =  user.filter(item => !selected.some(itemToBeRemoved => itemToBeRemoved.name === item.name))
    setUserList(arr)
}
const handleHoursChange = (id, value) => {
    let totalHrs = tableData.reduce((a,v) =>  a = parseInt(a) + parseInt(v.allocatedHrs) , 0 )
    setTableData((prev) =>{
        const updatedTableData = prev.map((entry) =>
          entry.id === id ? { ...entry, allocatedHrs: value } : entry
        );
        const totalAllocatedHrs = updatedTableData.reduce((sum, entry) => {
          const hours = parseInt(entry.allocatedHrs, 10) || 0;
          return sum + hours;
        }, 0);
        if(parseInt(data.allocatedHrs) < totalAllocatedHrs){
            setErrMsg('Total of allocated hours for team members should not be greater than allocated hours')
            setShowInputErr(true)
           }
           else{
            setShowInputErr(false)
            setErrMsg('')
           }
        return updatedTableData;
    })
  };
const handleProjectChange = (e) =>{
  if(e !== ''){
    setValidProjectName(false)
  }
  setData({ ...data, name : e.target.value }); 
}
const closeOutsideProject = () =>{
  setIsOpenProject(false)
}
const handleOpenProject = () =>{
  setIsOpenProject(true)
}
const closeOutsideUser = () =>{
  setIsOpenUser(false)
}
const handleOpenUser = () =>{
  setIsOpenUser(true)
}
const handleTime = (e) =>{
  let value = e.target.value;
  value = value.replace(/[^0-9:]/g, "");
  setData({ ...data, allocatedHrs : value }); 
  setAllocatedHrs(value)
}

const handleStartDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , startDate: stringDate})
  }
  else {
   setData({ ...data, startDate : null }); 
  }
}
const handleEndDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , endDate: stringDate})
  }
  else {
   setData({ ...data, endDate : null }); 
  }
}

const onChangeInput = (e, employeeId) => {
  const { name, value } = e.target
  let editData = []
   editData = teamMemberData.map((item) =>
   item.id === employeeId && name ? { ...item, [name]: value} : item
  )
  let totalHrs = editData.reduce((a,v) =>  a = parseInt(a) + parseInt(v.allocatedHrs) , 0 )
  setTeamMemberData(editData)
 setData({...data, teamMembers: editData})
 if(parseInt(data.allocatedHrs) < totalHrs){
  setErrMsg('Total of allocated hours for team members should not be greater than allocated hours')
  setShowInputErr(true)
 }
 else{
  setShowInputErr(false)
  setErrMsg('')
 }
}
return (
    <Fragment>
       <Form onSubmit={onSubmit}>
            <FormGroup>
                <Label for="exampleEmail">
                 Project Name
                </Label>
                <Input
                invalid = {projectNameValid}
                value={data?.name}
                id="exampleEmail"
                name="name"
                placeholder="Enter Project Name"
                type="text"
                onChange={handleProjectChange}
                />
                <FormFeedback>
                   Please enter project name
                </FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                 Allocated Hours
                </Label>
                <Input
                    type= "number"
                    value = {allocatedHrs}
                    onChange={handleTime}
                    placeholder="Enter Allocated Hours"
                  />
            </FormGroup>
            <div>
                <Row>
                    <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          Start Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.startDate && new Date((moment(data?.startDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleStartDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
                     <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          End Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.endDate && new Date((moment(data?.endDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleEndDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
            </Row>
            </div>
            <FormGroup>
                <Label for="examplePassword">
                 Project Lead
                </Label>
                <Typeahead
                  isInvalid={projectLeadValid}
                  id="basic-typeahead-single"
                  emptyLabel="No match found"
                  onFocus={handleOpenProject}
                  onBlur={closeOutsideProject}
                  open= {isOpenProject}
                  className='dropdownComp'
                  labelKey="name"
                  onChange={handleLeadClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  clearButton
                  placeholder="Select Project Lead"
                  selected={projectLead ? [projectLead] : []} 
                />
                  <FormFeedback>
                    Please select project lead
                 </FormFeedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: isOpenUser ? '80px' : 0}}>
            <Label for="examplePassword">
                 Team Member
                </Label>
                <Typeahead
                  id="userDropdown"
                  onBlur= {closeOutsideUser}
                  onFocus={handleOpenUser}
                  open= {isOpenUser}
                  labelKey="name"
                  multiple
                  isInvalid={projectMemberValid}
                  onChange={handleTeamMemberClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  placeholder="Select Team Member"
                  selected={teamMembers}
                />
                <FormFeedback>
                   Please select team member
                </FormFeedback>
            </FormGroup>
            <FormGroup>
              {showTeamMemberTable && 
            <table className="inputTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Allocated Hours</th>
          </tr>
        </thead>
        <tbody>
        {tableData.map((entry) => (
            <tr key={entry.id}>
               <td>{entry.name}</td>
              <td>
                <Input
                  className='tableInput'
                  name="allocatedHrs"
                  value={entry.allocatedHrs}
                  type="number"
                  onChange={(e) => handleHoursChange(entry.id, e.target.value)}
                  placeholder="Enter Hours"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
}
            </FormGroup>
            {showInputErr && 
            <div style={{color: 'red'}}>
              <li>{errMsg}</li>
              </div>}
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick={closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}
