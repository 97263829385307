import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Modal.css';

type Props = {
    isOpen : boolean;
    toggle ?:any;
    handleDelete ?: any;
    modalBody ?: any;
    handleDeactivate ?: any;
    formType ?:any;
}
export const DeleteModalComp: React.FC<Props> = ({ isOpen,toggle, formType,handleDelete,handleDeactivate,modalBody}: Props) => {


  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm delete</ModalHeader>
        <ModalBody>
        {modalBody}
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={toggle}>
            No
          </Button>
          <Button color="primary" onClick={handleDelete}>
             Yes
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
