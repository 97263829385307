import React, { Fragment, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback } from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Login.css'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { forgotPassword } from '../store/UserAction';


type Props = {
    closeModal ?: any;
  }
export const ForgotPassword: React.FC<Props> = ({closeModal}: Props) =>  {
const [emailValid, setEmailValid] = useState(false);
const [email, setEmail] = useState()
const dispatch = useAppDispatch()
const loading = useAppSelector(state=>state.user.loading);

const onSubmit = (e) =>{
 var re = /\S+@\S+\.\S+/;
 let flag = false
 e.preventDefault()
  if((email == undefined) || (!re.test(email))){
    setEmailValid(true)
    flag = true
  }
  if(!flag){
    dispatch<any>(forgotPassword({email}))
  }
}
const handleInputChange = (e) =>{
 setEmail(e.target.value)
 setEmailValid(false)
}

return (
    <Fragment>
        <div className="forgotPassform">
       <Form  onSubmit={onSubmit}>
            <FormGroup>
               <h6 style={{textAlign: 'center', fontWeight:'bold', fontSize: '1.5rem'}}>Forgot your password?</h6>
               <p style={{textAlign: 'center', fontSize: '1rem' }}>Enter your email address below and we will send you a link to reset your password</p>
                <Input
                style={{'marginBottom': '28px'}}
                value={email}
                invalid={emailValid}
                id="exampleEmail"
                name="email"
                placeholder="enter email id"
                type="text"
                onChange={handleInputChange}
                />
               <FormFeedback>
                    Please enter proper email id
                </FormFeedback>
            </FormGroup>
            <hr style={{marginTop: '10px'}} />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick={closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Sending email...' : 'Send'}</Button>
            </div>
            
        </Form>
        </div>
    </Fragment>
  )
}
