import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {UserModel } from "../Models/UserModel";
import { ProjectModel } from "../Models/ProjectModel";
import { WorklogModel } from "../Models/WorklogModel";
import { fetchHolidayList, fetchUserDetails, fetchVerifiedUser } from "./UserAction";
import { VerifiedUserModel } from "../Models/VerifiedUserModel"
import { Company } from "../Models/CompanyModel";
import { HolidayModel } from "../Models/HolidayModel";
import { userDetailsModel } from "../Models/UserDetails";
import { PublicHolidayModel } from "../Models/PublicHolidayModel";
import { fabClasses } from "@mui/material";

export interface initialUserState{
    userList : UserModel[]
    loading : boolean
    error : string
    getUserFlag : boolean
    userUpdated : boolean
    userAdded : boolean
    userDeleted : boolean
    projectList : ProjectModel[]
    userProjectList : ProjectModel[]
    projectListByUsers : ProjectModel[]
    projectTeamLeadList : ProjectModel[]
    getProjectFlag : boolean
    getUserProjectFlag : boolean
    getProjectListByUser : boolean
    getProjectTeamLeadFlag : boolean
    projectUpdated : boolean
    projectAdded : boolean
    projectDeleted : boolean
    workLogList : WorklogModel[]
    verifiedUsers: VerifiedUserModel[]
    verifiedTeamLeadUsers: VerifiedUserModel[]
    getWorklogFlag : boolean
    worklogUpdated : boolean
    worklogAdded : boolean
    worklogDeleted : boolean
    confirmUser : boolean
    forgotPassword : boolean
    resetPassword : boolean
    selectedWorkLog : {},
    getWorklogByIdFlag : boolean,
    errorUserMsg : string,
    errorProjectMsg : string,
    errorProjectListMsg : string,
    errorAllProjectByUserMsg : string,
    errorWorklogMsg : string,
    errorUserVerification : string,
    errorForgotPassword : string,
    errorGetWorklog : string,
    resendSuccess : boolean,
    errorResetPassword : string,
    getVerifiedUsers : boolean,
    getVerifiedTeamLeadUsers : boolean
    errorGetVerifiedUser : string
    errorGetVerifiedTeamLeadUser : string
    workLogUserList :  WorklogModel[]
    workLogTeamLeadList : WorklogModel[]
    projectActivated : boolean,
    projectDeactivated : boolean
    companyList : Company[],
    getCompanyFlag : boolean,
    companyUpdated : boolean,
    companyAdded : boolean,
    companyDeleted : boolean,
    errorMsgCompany : string,
    holidayList : HolidayModel[],
    getHolidayList : boolean,
    holidayAdded : boolean,
    holidayDeleted : boolean,
    errorHolidayMsg : string
    userDetails : {},
    errorUserDetails : string
    getUserDetails : boolean
    verifiedUserFilter : VerifiedUserModel[],
    getVerifiedUserFilter : boolean,
    holidayUpdated : boolean,
    publicHolidayList : {},
    getPublicHolidayList : boolean,
    errorMsgPublicHoliday : string,
    publicHolidayAdded : boolean,
    publicHolidayUpdated : boolean,
    holidayPublicDeleted :boolean,
    usersPublicHolidayList : HolidayModel[],
    getUsersPublicHolidayList : boolean,
    errorMsgUsersPublicHoliday : string
}

const initialUserState : initialUserState ={
  loading: false,
  error: '',
  getUserFlag: false,
  userUpdated: false,
  userAdded: false,
  userDeleted : false,
  userList: [],
  verifiedUsers: [],
  verifiedTeamLeadUsers : [],
  projectList : [],
  userProjectList : [],
  projectListByUsers : [],
  projectTeamLeadList : [],
  getProjectFlag : false,
  getUserProjectFlag : false,
  getProjectListByUser : false,
  getProjectTeamLeadFlag : false,
  projectUpdated : false,
  projectAdded : false,
  projectDeleted : false,
  workLogList : [],
  workLogUserList : [],
  getWorklogFlag : false,
  worklogUpdated : false,
  worklogAdded : false,
  worklogDeleted : false,
  confirmUser : false,
  forgotPassword : false,
  resetPassword : false,
  selectedWorkLog : {},
  getWorklogByIdFlag : false,
  errorUserMsg : '',
  errorProjectMsg : '',
  errorProjectListMsg : '',
  errorAllProjectByUserMsg : '',
  errorWorklogMsg : '',
  errorUserVerification : '',
  errorForgotPassword : '',
  errorResetPassword : '',
  errorGetWorklog : '',
  resendSuccess : false,
  getVerifiedUsers : false,
  getVerifiedTeamLeadUsers : false,
  errorGetVerifiedUser: '',
  errorGetVerifiedTeamLeadUser : '',
  workLogTeamLeadList : [],
  projectActivated : false,
  projectDeactivated : false,
  companyList : [],
  getCompanyFlag : false,
  companyUpdated : false,
  companyAdded : false,
  companyDeleted : false,
  errorMsgCompany: '',
  holidayList : [],
  getHolidayList : false,
  holidayAdded : false,
  holidayDeleted : false,
  errorHolidayMsg : '',
  userDetails : {},
  errorUserDetails : '',
  getUserDetails : false,
  verifiedUserFilter : [],
  getVerifiedUserFilter : false,
  holidayUpdated : false,
  publicHolidayList : {},
  getPublicHolidayList : false,
  errorMsgPublicHoliday : '',
  publicHolidayAdded : false,
  publicHolidayUpdated : false,
  holidayPublicDeleted : false,
  usersPublicHolidayList : [],
  getUsersPublicHolidayList : false,
  errorMsgUsersPublicHoliday : ''
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers:{
        setLoading(state){
          return{
            ...state,
            loading : true,
          }
        },
        setFinishLoading(state){
          return {
            ...state,
            loading: false,
         }
        },
        reset(state){
          return {
            ...state,
            getWorklogFlag: false,
            getProjectListByUser: false
        }
        },
        setUser(state,action: PayloadAction<UserModel[] | any>){
          const listArr = [...action.payload].filter(user => {
            return user;
           });
            state.userList = listArr
            state.getUserFlag = true;
            state.userAdded = false
            state.userDeleted = false
            state.userUpdated = false
            state.resendSuccess = false
        },
        setLoginError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.error =  action.payload.response?.status
          state.loading = false
        },
        fetchWorklogError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorWorklogMsg =  action.payload.response?.status
          state.loading = false
        },
        addWorklogError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorWorklogMsg =  action.payload.response?.status
          state.loading = false
        },
        updateWorklogError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorWorklogMsg =  action.payload.response?.status
          state.loading = false
        },
        deleteWorklogError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorWorklogMsg =  action.payload.response?.status
          state.loading = false
        },
        fetchProjectError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
          state.getProjectFlag = false
        },
        fetchProjectTeamLeadError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
          state.getProjectTeamLeadFlag = false
        },
        addProjectError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
        },
        editProjectError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
        },
        deleteProjectError(state,action: PayloadAction<ProjectModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
        },
        deactivateProjectError(state,action: PayloadAction<ProjectModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
        },
        activeProjectError(state,action: PayloadAction<ProjectModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectMsg =  action.payload.response?.status
          state.loading = false
        },
        fetchUserError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorUserMsg = action.payload.response?.status
          state.loading = false
        },
        addUserError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorUserMsg =  action.payload.response?.status
          state.loading = false
        },
        editUserError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorUserMsg =  action.payload.response?.status
          state.loading = false
        },
        deleteUserError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorUserMsg =  action.payload.response?.status
          state.loading = false
        },
        userVerificationError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorUserVerification =  action.payload.response?.status
          state.loading = false
        },
        forgotPassowrdError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorForgotPassword =  action.payload.response?.status
          state.loading = false
        },
        resetPasswordError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorResetPassword =  action.payload.response?.status
          state.loading = false
        },
        getWorklogError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorGetWorklog =  action.payload.response?.status
          state.loading = false
        },
        updateUser(state,action: PayloadAction<UserModel[] | any>){
          if ((action.payload.id) == (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
            localStorage.setItem('user',JSON.stringify(action.payload))
          }
         return {
          ...state,
          userUpdated : true,
          getUserFlag : false
         }
        },
        addUser(state = initialUserState,action: PayloadAction<UserModel[]>){
          return {
           ...state,
           userAdded : true,
           getUserFlag : false
          }
        },
        deleteUser(state = initialUserState,action: PayloadAction<UserModel[]>){
          return {
           ...state,
           userDeleted : true,
           getUserFlag : false
          }
         },
         fetchProject(state,action: PayloadAction<ProjectModel[] | any>){
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.projectList = listArr
            state.getProjectFlag = true;
            state.projectUpdated = false
            state.projectAdded = false
            state.projectDeleted = false
            state.projectActivated = false
            state.projectDeactivated = false
            state.errorProjectMsg = ''
        },
        fetchProjectListError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorProjectListMsg =  action.payload.response?.status
          state.loading = false
          state.getUserProjectFlag = false
        },
        fetchProjectList(state,action: PayloadAction<ProjectModel[] | any>){
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.userProjectList = listArr
            state.getUserProjectFlag = true;
            state.errorProjectListMsg = ''
        },
        fetchUsersProjectListError(state,action: PayloadAction<UserModel[]| any>){
          let code = action.payload.response?.status
          if(code == '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorAllProjectByUserMsg =  action.payload.response?.status
          state.loading = false
          state.getProjectListByUser = false
        },
        fetchUsersProjectList(state,action: PayloadAction<ProjectModel[] | any>){
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.projectListByUsers = listArr
            state.getProjectListByUser = true;
            state.errorAllProjectByUserMsg = ''
        },
        fetchTeamLeadProject(state,action: PayloadAction<ProjectModel[] | any>){
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.projectTeamLeadList = listArr
            state.getProjectTeamLeadFlag = true;
            state.projectUpdated = false
            state.projectAdded = false
            state.projectDeleted = false
            state.projectActivated = false
            state.projectDeactivated = false
            state.errorProjectMsg = ''
        },
        updateProject(state,action: PayloadAction<ProjectModel[] | any>){
          return {
           ...state,
           projectUpdated : true,
           getProjectFlag : false,
           getProjectTeamLeadFlag : false,
           errorProjectMsg : ''
          }
         },
         addProject(state = initialUserState,action: PayloadAction<ProjectModel[]>){
          return {
           ...state,
           projectAdded : true,
           getProjectFlag : false,
           getProjectTeamLeadFlag : false,
           errorProjectMsg : ''
          }
        },
        deleteProject(state = initialUserState,action: PayloadAction<ProjectModel[]>){
          return {
           ...state,
           projectDeleted : true,
           getProjectFlag : false,
           getProjectTeamLeadFlag : false,
           errorProjectMsg : ''
          }
        },
        deactivateProject(state = initialUserState,action: PayloadAction<ProjectModel[]>){
          return {
           ...state,
           projectDeactivated : true,
           getProjectFlag : false,
           getProjectTeamLeadFlag : false,
           errorProjectMsg : ''
          }
        },
        activeProject(state = initialUserState,action: PayloadAction<ProjectModel[]>){
          return {
           ...state,
           projectActivated : true,
           getProjectFlag : false,
           getProjectTeamLeadFlag : false,
           errorProjectMsg : ''
          }
        },
        fetchWorklog(state = initialUserState,action: PayloadAction<WorklogModel[] | any>){
          const listArr = [...action.payload].filter(worklog => {
            return worklog;
          });
            state.workLogList = listArr
            state.getWorklogFlag = true;
            state.worklogAdded = false;
            state.worklogUpdated = false;
            state.worklogDeleted = false;
            state.getWorklogByIdFlag = false;
            state.errorGetWorklog = ''
            state.errorWorklogMsg=''
        },
        fetchUserWorklog(state = initialUserState,action: PayloadAction<WorklogModel[] | any>){
          const listArr = [...action.payload].filter(worklog => {
            return worklog;
          });
            state.workLogUserList = listArr
            state.getWorklogFlag = true;
            state.worklogAdded = false;
            state.worklogUpdated = false;
            state.worklogDeleted = false;
            state.getWorklogByIdFlag = false;
            state.errorGetWorklog = ''
            state.errorWorklogMsg=''
        },
        fetchTeamLeadWorklog(state = initialUserState,action: PayloadAction<WorklogModel[] | any>){
          const listArr = [...action.payload].filter(worklog => {
            return worklog;
          });
            state.workLogTeamLeadList = listArr
            state.getWorklogFlag = true;
            state.worklogAdded = false;
            state.worklogUpdated = false;
            state.worklogDeleted = false;
            state.getWorklogByIdFlag = false;
            state.errorGetWorklog = ''
            state.errorWorklogMsg=''
        },
        updateWorklog(state = initialUserState,action: PayloadAction<WorklogModel[] | any>){
          return {
           ...state,
           worklogUpdated : true,
           getWorklogFlag : false,
           errorWorklogMsg : '',
           getProjectTeamLeadFlag : false,
           getProjectFlag : false
          }
         },
         addWorklog(state = initialUserState,action: PayloadAction<WorklogModel[]>){
          return {
           ...state,
           worklogAdded : true,
           getWorklogFlag : false,
           errorWorklogMsg : '',
           getProjectTeamLeadFlag : false,
           getProjectFlag : false
          }
        },
        deleteWorklog(state = initialUserState,action: PayloadAction<WorklogModel[]>){
          return {
           ...state,
           worklogDeleted : true,
           getWorklogFlag : false,
           errorWorklogMsg : ''
          }
        },
        verifyUser(state){
           return {
            ...state,
            confirmUser : true
           }
        },
        forgotPassword(state){
          return {
           ...state,
           forgotPassword : true,
           errorForgotPassword : ''
          }
        },
        resetPassword(state){
          return {
           ...state,
           resetPassword : true
          }
        },
        fetchWorklogById(state = initialUserState,action){
            state.selectedWorkLog = action.payload
            state.getWorklogByIdFlag = true;
        },
        resendEmail(state){
          state.resendSuccess = true
        },
        setVerifiedUser(state,action: PayloadAction<UserModel[] | any>){
          if (action.payload.id === (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
            localStorage.setItem('user',JSON.stringify(action.payload))
          }
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
          
            state.verifiedUsers = listArr
            state.getVerifiedUsers = true;
        },
        setVerifiedTeamLeadUser(state,action: PayloadAction<UserModel[] | any>){
          if (action.payload.id === (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
            localStorage.setItem('user',JSON.stringify(action.payload))
          }
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.verifiedTeamLeadUsers = listArr
            state.getVerifiedTeamLeadUsers = true;
        },
        setVerifiedTeamLeadUserFilter(state,action: PayloadAction<UserModel[] | any>){
          if (action.payload.id === (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
            localStorage.setItem('user',JSON.stringify(action.payload))
          }
          const listArr = [...action.payload].filter(user => {
                        return user;
           });
            state.verifiedUserFilter = listArr
            state.getVerifiedUserFilter = true;
        },
        getVerifiedUserFilterError(state,action: PayloadAction<VerifiedUserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorGetVerifiedUser = action.payload
          state.loading = false
        },
        getVerifiedUserError(state,action: PayloadAction<VerifiedUserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorGetVerifiedUser = action.payload
          state.loading = false
        },
        getVerifiedTeamLeadUserError(state,action: PayloadAction<VerifiedUserModel[]| any>){
          let code = action.payload.response?.status
          if(code === '401'){
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
          }
          state.errorGetVerifiedTeamLeadUser = action.payload
          state.loading = false
        },
       fetchCompany(state,action: PayloadAction<ProjectModel[] | any>){
        const listArr = [...action.payload].filter(user => {
                      return user;
         });
          state.companyList = listArr
          state.getCompanyFlag = true;
          state.companyUpdated = false
          state.companyAdded = false
          state.companyDeleted = false
          state.errorProjectMsg = ''
      },
      updateCompany(state = initialUserState,action: PayloadAction<Company[] | any>){
        return {
         ...state,
         companyUpdated : true,
         getCompanyFlag : false,
         errorMsgCompany : '',
         getProjectFlag : false
        }
       },
       addCompany(state = initialUserState,action: PayloadAction<Company[]>){
        return {
         ...state,
         companyAdded : true,
         getCompanyFlag : false,
         errorMsgCompany : '',
        }
      },
      deleteCompany(state = initialUserState,action: PayloadAction<Company[]>){
        return {
         ...state,
         companyDeleted : true,
         getCompanyFlag : false,
         errorMsgCompany : ''
        }
      },
      fetchCompanyError(state,action: PayloadAction<UserModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgCompany = action.payload.response?.status
        state.loading = false
      },
      addCompanyError(state,action: PayloadAction<UserModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgCompany =  action.payload.response?.status
        state.loading = false
      },
      editCompanyError(state,action: PayloadAction<UserModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgCompany =  action.payload.response?.status
        state.loading = false
      },
      deleteCompanyError(state,action: PayloadAction<UserModel[]| any>){
        let code = action.payload.response?.status
        if(code === '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgCompany =  action.payload.response?.status
        state.loading = false
      },
      fetchHolidayList(state = initialUserState,action: PayloadAction<HolidayModel[] | any>){
        const listArr = [...action.payload].filter(holiday => {
          return holiday;
        });
          state.holidayList = listArr
          state.getHolidayList = true;
          state.holidayAdded = false;
          state.holidayDeleted = false;
          state.holidayUpdated = false;
          state.errorHolidayMsg=''
      },
      addHoliday(state = initialUserState,action: PayloadAction<HolidayModel[]>){
        return {
         ...state,
         holidayAdded : true,
         getHolidayList : false,
         errorHolidayMsg : '',
        }
      },
      deleteHoliday(state = initialUserState,action: PayloadAction<HolidayModel[]>){
        return {
         ...state,
         holidayDeleted : true,
         getHolidayList : false,
         errorHolidayMsg : ''
        }
      },
      fetchHolidayError(state,action: PayloadAction<HolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorHolidayMsg = action.payload.response?.status
        state.loading = false
      },
      addHolidayError(state,action: PayloadAction<HolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorHolidayMsg =  action.payload.response?.status
        state.loading = false
      },
      deleteHolidayError(state,action: PayloadAction<HolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code === '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorHolidayMsg =  action.payload.response?.status
        state.loading = false
      },
      fetchUserDetails(state = initialUserState,action: PayloadAction<userDetailsModel[] | any>){
          state.userDetails = action.payload
          state.getUserDetails = true;
          state.errorUserDetails=''
      },
      fetchUserDetailsError(state,action: PayloadAction<userDetailsModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorUserDetails = action.payload.response?.status
        state.loading = false
      },
      updateHoliday(state = initialUserState,action: PayloadAction<HolidayModel[] | any>){
        return {
         ...state,
         holidayUpdated : true,
         getHolidayFlag : false,
         errorHolidayMsg : '',
        }
       },
      editHolidayError(state,action: PayloadAction<HolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorHolidayMsg =  action.payload.response?.status
        state.loading = false
      },
      fetchPublicHolidayDetails(state = initialUserState,action: PayloadAction<PublicHolidayModel[] | any>){
          state.publicHolidayList = action.payload
          state.getPublicHolidayList = true
          state.errorMsgPublicHoliday=''
          state.publicHolidayAdded = false
          state.publicHolidayUpdated = false
          state.holidayPublicDeleted = false
      },
      fetchPublicHolidayError(state,action: PayloadAction<PublicHolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgPublicHoliday = action.payload.response?.status
        state.loading = false
      },
      addPublicHoliday(state = initialUserState,action: PayloadAction<PublicHolidayModel[]>){
        return {
         ...state,
         publicHolidayAdded : true,
         getPublicHolidayList : false,
         errorMsgPublicHoliday : '',
        }
      },
      updatePublicHoliday(state = initialUserState,action: PayloadAction<PublicHolidayModel[] | any>){
        return {
         ...state,
         publicHolidayUpdated : true,
         getPublicHolidayList : false,
         errorMsgPublicHoliday : '',
        }
      },
      addPublicHolidayError(state,action: PayloadAction<PublicHolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgPublicHoliday =  action.payload.response?.status
        state.loading = false
      },
      deletePublicHolidayError(state,action: PayloadAction<PublicHolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code === '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgPublicHoliday =  action.payload.response?.status
        state.loading = false
      },
      editPublicHolidayError(state,action: PayloadAction<PublicHolidayModel[]| any>){
        let code = action.payload.response?.status
        if(code == '401'){
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
        }
        state.errorMsgPublicHoliday =  action.payload.response?.status
        state.loading = false
      },
      deletePublicHoliday(state = initialUserState,action: PayloadAction<PublicHolidayModel[]>){
        return {
         ...state,
         holidayPublicDeleted : true,
         getPublicHolidayList : false,
         errorMsgPublicHoliday : ''
        }
      },
      fetchUsersPublicHolidays(state = initialUserState,action: PayloadAction<PublicHolidayModel[] | any>){
        state.usersPublicHolidayList = action.payload
        state.getUsersPublicHolidayList = true
        state.errorMsgUsersPublicHoliday=''
      },
     fetchUsersPublicHolidaysError(state,action: PayloadAction<PublicHolidayModel[]| any>){
      let code = action.payload.response?.status
      if(code == '401'){
        localStorage.setItem('token', null);
        localStorage.setItem('user', null);
      }
      state.errorMsgUsersPublicHoliday = action.payload.response?.status
      state.loading = false
    },
    }
})

export default userSlice;