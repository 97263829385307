import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Navbar } from '../components/Navbar'
import { Button, Col, Row } from 'reactstrap'
import { ModalComp } from './Modal'
import { AddUser } from './AddUser'
import { DeleteModalComp } from './DeleteModal'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { deleteCompany, deletePublicHoliday, deleteUser, fetchCompany, fetchPublicHoliday, fetchUser } from '../store/UserAction'
import ProjectTable from '../components/Table/ProjectTable'
import Loader from 'react-loader-advanced';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import { Navigate, useNavigate } from 'react-router-dom'
import { AddCompany } from './AddCompany'
import { Typeahead } from 'react-bootstrap-typeahead'
import { AddPublicHoliday } from './AddPublicHoliday'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { RiPencilFill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import { Box } from '@mui/material'


export const ManagePublicHoliday: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [deletedRow, setDeletedRow] = useState()
  const dispatch=useAppDispatch()
  const company = useAppSelector(state=>state.user.companyList)
  const publicHoliday = useAppSelector(state=>state.user.publicHolidayList)
  const publicHolidayAdded = useAppSelector(state=>state.user.publicHolidayAdded)
  const publicHolidayUpdated = useAppSelector(state=>state.user.publicHolidayUpdated)
  const holidayPublicDeleted = useAppSelector(state=>state.user.holidayPublicDeleted)
  const getCompanyFlag = useAppSelector(state=>state.user.getCompanyFlag);
  const getPublicHolidayList = useAppSelector(state=>state.user.getPublicHolidayList)
  const [data, setData] = useState([])
  const errorMsg= useAppSelector(state=>state.user.errorUserMsg);
  const loading = useAppSelector(state=>state.user.loading);
  const spinner4 = <BeatLoader style={{display: 'block'}} color='#ffffff' loading={loading} size={15} aria-label="Loading Spinner" />
  const navigate = useNavigate()
  const [selectedCompany,setSelectedCompany] =  useState([]) 
  const [publicHolidayList,setPublicHolidayList] = useState([])

 useEffect(() => {
  if(errorMsg !== ''){
    if(errorMsg == '401'){
      navigate('/login')
    }
    toast.error('Somthing went wrong', {
      position: "top-right",
    });
   }
  if(publicHolidayAdded === true){
    toast.success("Public holiday added successfully!", {
      position: "top-right",
    });
    setShowModal(false)
  }
  if(publicHolidayUpdated === true){
    toast.success("Public holiday updated successfully!", {
        position: "top-right",
      });
      setShowEditModal(false)
  }
  if(holidayPublicDeleted === true){
    toast.success("Public holiday deleted successfully!", {
        position: "top-right",
      });
      setShowDeleteModal(false)
  }
  if(getCompanyFlag){
    if(selectedCompany.length > 0){
     setSelectedCompany([selectedCompany[0]])
    }
    else{
      setSelectedCompany([company[0]])
    }

  }
  if(getPublicHolidayList){
    if(publicHoliday !== undefined){
    let outputArray = [];
    Object.entries(publicHoliday).forEach(([company, holiday]) => {
      if(Array.isArray(holiday)){ 
        holiday.forEach(holiday => {
          outputArray.push({ company, ...holiday });
        });
      }
      });
      const filteredData = outputArray.filter(item => item.company === selectedCompany.length > 0 ? selectedCompany[0]?.name : company[0]?.name);
      setPublicHolidayList(filteredData)
    }
  }
  dispatch<any>(fetchCompany());
  dispatch<any>(fetchPublicHoliday());
  setData(company)
  let outputArray = [];
    Object.entries(publicHoliday).forEach(([company, holiday]) => {
      if(Array.isArray(holiday)){
        holiday.forEach(holiday => {
          outputArray.push({ company, ...holiday });
        });
      }
});
  const filteredData = outputArray.filter(item => selectedCompany.length > 0 ? item?.company === selectedCompany[0]?.name : item?.company === company[0]?.name);
  setPublicHolidayList(filteredData)
  },[dispatch,errorMsg,getPublicHolidayList,getCompanyFlag])

const openEditModal = () =>{
  setShowModal(true)
}
const closeModal = () =>{
    setShowModal(false)
    setShowDeleteModal(false)
    setShowEditModal(false)
}

const handleDelete =(row) =>{
  setShowDeleteModal(true)
  setDeletedRow(row?.original)
}
const handleEdit = (row) =>{
   setShowEditModal(true)
   setRowData(row?.original)
}
const handleDeleteRow = () =>{
  let id = deletedRow ? deletedRow['id'] : 'null'
  dispatch<any>(deletePublicHoliday(id));
  setShowDeleteModal(false)
}
const handleCompanyChange = (e) =>{
    if(e[0] !== undefined){
     setSelectedCompany(e)
     let outputArray = [];
     Object.entries(publicHoliday).forEach(([company, holiday]) => {
       if(Array.isArray(holiday)){
         holiday.forEach(holiday => {
           outputArray.push({ company, ...holiday });
         });
       }
  });
     const filteredData = outputArray.filter(item => item.company === e[0].name);
     setPublicHolidayList(filteredData)
    }
    else{
     setSelectedCompany([])
     setPublicHolidayList([])
    }
}

const columns=useMemo(
  () => [
  {
  header : 'Date',
  accessorFn: (row) => {
   return(
      `${row.date}`
   )
  }
 },
 {
  header : 'Name',
  accessorFn: (row) => {
   return(
    `${row.name}`
       )
  }
 },
 {
  header : 'Type',
  accessorFn: (row) => {
  let optionalHoliday = row?.optional === true ? 'optional' : ''
   return(
    `${optionalHoliday}`
       )
  }
 }
],
[],
);
const table = useMaterialReactTable({
  columns :  columns,
  data : publicHolidayList,
  initialState: { pagination:{pageSize: 5,pageIndex: 0},columnVisibility: { id: false } },
  muiTableBodyRowProps: ({ row }) => ({
    //conditionally style expanded rows
    sx: {
      backgroundColor:  'white',
    },
  }),
  displayColumnDefOptions: {
    'mrt-row-actions': {
      size: 120, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
      grow: false,
      },
  },
  enableTopToolbar: false,
  enableSorting:false,
  enableColumnActions:false,

  enableRowActions: true,
  renderRowActions: ({ row }) => (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
          <RiPencilFill  style={{cursor:  'pointer' , width: '25px' , height: '25px'}} onClick={() => handleEdit(row)} />
         
          <MdDelete id='deleteIcon' style={{cursor: 'pointer'}} onClick={() => handleDelete(row)}/>
     </Box>    
  ),
});
let formTemplate = <AddPublicHoliday formType={showModal ? 'add' : 'edit'} closeModal={closeModal} rowData={showModal ? {} :rowData} />
  return (
    <Fragment>
       <ToastContainer />
      {showModal === true &&
        <ModalComp isOpen={showModal} modalTitle='Add public holidays' id="addUser" modalBody={formTemplate} toggle={closeModal}/>
      }
      {showEditModal === true &&
        <ModalComp isOpen={showEditModal} modalTitle='Edit public holidays'id="addUser"   modalBody={formTemplate} toggle={closeModal}/>
      }
      {showDeleteModal === true &&
        <DeleteModalComp isOpen={showDeleteModal} modalBody={'Are you sure you want to delete the value?'} toggle={closeModal} handleDelete={handleDeleteRow}/>
      }
        <Navbar />
        <div className='projectDetails'>
        <Row style={{paddingRight: '13px', paddingLeft: '10px'}}>
            <div className='projectLabel'>
                <Col>
                    <h5>Public Holiday</h5>
                </Col>
                <Col style={{display: 'flex', justifyContent:'end'}}>
                    <Button onClick={openEditModal}>Add Public Holiday</Button>
                </Col>
            </div>
        </Row>
        <Row>
            <Col style={{maxWidth: '20%',paddingBottom: '20px'}}>
                  <Typeahead
                    className='dropdownComp'
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleCompanyChange}
                    options={company}
                    clearButton
                    placeholder="Select company"
                    selected={selectedCompany}
                  />
              </Col>
          </Row>
        <Row> 
          <Loader message={spinner4} show={loading}>
           <div>
             <MaterialReactTable
                table={table}
              />
           </div>
           </Loader>
          </Row>

        </div>
      {/* <button
        type="button"
        className="btn"
        cy-data="go-back-button"
        onClick={() => navigate('/')}
      >
        Go back
      </button> */}
    </Fragment>
  )
}
