import React, { Fragment, useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar'
import { Button, Col, Row } from 'reactstrap'
import { ModalComp } from './Modal'
import { AddUser } from './AddUser'
import { DeleteModalComp } from './DeleteModal'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { deleteUser, fetchUser } from '../store/UserAction'
import ProjectTable from '../components/Table/ProjectTable'
import Loader from 'react-loader-advanced';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import { Navigate, useNavigate } from 'react-router-dom'


export const ManageUser: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [deletedRow, setDeletedRow] = useState()
  const dispatch=useAppDispatch()
  const user = useAppSelector(state=>state.user.userList)
  const userUpdated = useAppSelector(state=>state.user.userUpdated);
  const userAdded= useAppSelector(state=>state.user.userAdded);
  const userDeleted= useAppSelector(state=>state.user.userDeleted);
  const getUserFlag = useAppSelector(state=>state.user.getUserFlag);
  const [data, setData] = useState([])
  const errorMsg= useAppSelector(state=>state.user.errorUserMsg);
  const loading = useAppSelector(state=>state.user.loading);
  const spinner4 = <BeatLoader style={{display: 'block'}} color='#ffffff' loading={loading} size={15} aria-label="Loading Spinner" />
  const resendSuccess = useAppSelector(state => state.user.resendSuccess)
  const resendEmail = useAppSelector(state=>state.user.resendSuccess);
  const navigate = useNavigate()
  
 useEffect(()=>{
  if(errorMsg !== ''){
    if(errorMsg == '401'){
      navigate('/login')
    }
    toast.error('Somthing went wrong', {
      position: "top-right",
    });
  }
  if(getUserFlag){
    setData(user)
  }
  if(userUpdated === true){
    toast.success("User updated successfully!", {
      position: "top-right",
    });
    setShowEditModal(false)
  }
  if(userAdded === true){
    toast.success("New user added successfully!", {
        position: "top-right",
      });
      setShowModal(false)
  }
  if(resendEmail === true){
    toast.success("Resend mail successfully!", {
      position: "top-right",
    });
}
  if(userDeleted === true){
    toast.success("User deleted successfully!", {
        position: "top-right",
      });
      setShowDeleteModal(false)
  }
  dispatch<any>(fetchUser());
  setData(user)
  },[dispatch,userUpdated,getUserFlag,errorMsg,userAdded,resendSuccess,userDeleted,resendEmail])

const openEditModal = () =>{
  setShowModal(true)
}
const closeModal = () =>{
    setShowModal(false)
    setShowDeleteModal(false)
    setShowEditModal(false)
}
const addTableData = (newData) =>{
  // if((!showEditModal)){
  //   setData([...data,newData])
  // }
  // else{
  //   const updatedArr = data.map(
  //     item => item.id === newData.id ? {...newData} : {...item}
  //   );
  //   setData(updatedArr);
  // }
  setShowModal(false)
  setShowEditModal(false)
}
const handleDelete =(row) =>{
  setShowDeleteModal(true)
  setDeletedRow(row?.original)
}
const handleEdit = (row) =>{
   setShowEditModal(true)
   setRowData(row?.original)
}
const handleDeleteRow = () =>{
  let id = deletedRow ? deletedRow['id'] : 'null'
  dispatch<any>(deleteUser(id));
  setShowDeleteModal(false)
}
let formTemplate = <AddUser formType={showModal ? 'add' : 'edit'} closeModal={closeModal} rowData={showModal ? {} :rowData} addTableData={addTableData}/>
  return (
    <Fragment>
      <ToastContainer />
      {showModal === true &&
        <ModalComp isOpen={showModal} modalTitle='Add User Details' id="addUser" modalBody={formTemplate} toggle={closeModal}/>
      }
      {showEditModal === true &&
        <ModalComp isOpen={showEditModal} modalTitle='Edit User Details'id="addUser"   modalBody={formTemplate} toggle={closeModal}/>
      }
      {showDeleteModal === true &&
        <DeleteModalComp isOpen={showDeleteModal} modalBody={'Are you sure you want to delete the value?'} toggle={closeModal} handleDelete={handleDeleteRow}/>
      }
        <Navbar />
        <div className='projectDetails'>
        <Row style={{paddingRight: '13px', paddingLeft: '10px'}}>
            <div className='projectLabel'>
                <Col>
                    <h5>Users</h5>
                </Col>
                <Col style={{display: 'flex', justifyContent:'end'}}>
                    <Button onClick={openEditModal}>Add user</Button>
                </Col>
            </div>
        </Row>
        <Row> 
          <Loader message={spinner4} show={loading}>
           <div>
            <ProjectTable data={data} tableName='user' editEventHandler={handleEdit} deleteEventHandler={handleDelete}/>
           </div>
           </Loader>
          </Row>

        </div>
      {/* <button
        type="button"
        className="btn"
        cy-data="go-back-button"
        onClick={() => navigate('/')}
      >
        Go back
      </button> */}
    </Fragment>
  )
}
