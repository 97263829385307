import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Col, Row } from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addCompany,  addHoliday,  editCompany, editHoliday, fetchUsersPublicHoliday} from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import DatePicker, { registerLocale } from 'react-datepicker';
import './ManageUser.css';
import moment from 'moment';
import { isEqual, isSameDay, parse } from 'date-fns';
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';

type Props = {
    formType : string;
    rowData ?: any;
    addTableData ?: any;
    closeModal ?: any;
    leaveBalance ?: any;
    holidayList ?: any;
}
type FormDataProps ={
  fromDate ?: any;
  toDate ?: any;
  noOfDays ?: any;
  holidayFromDate ?: any;
  halfDay ?: any;
  halfDayType ?: any;
  leaveType ?: any;
}

export const AddHolidays: React.FC<Props>  = ({formType,rowData,closeModal,leaveBalance,holidayList}: Props) =>  {
const [data, setData] =useState<FormDataProps>(rowData);
const loading = useAppSelector(state=>state.user.loading);
const dispatch=useAppDispatch()
const [halfDayChecked,setHalfDayChecked] = useState(false)
const [fromDateValid, setFromDateValid] = useState(false)
const [toDateValid, setToDateValid] = useState(false)
const [errMsg , setErrMsg] = useState('')
const getUsersPublicHolidayList = useAppSelector(state=>state.user.getUsersPublicHolidayList)
const usersPublicHolidayList = useAppSelector(state=>state.user.usersPublicHolidayList)
const [selectedHalfDayType, setSelectedHalfDayType] = useState([{id:1,name: 'First half'}]);
const [selectedLeaveType, setSelectedLeaveType] = useState([{id:1,name: 'PL'}]);


useEffect(()=>{
  if(formType === 'edit'){
    let array = data?.leaveType?.split(" ");
    let myArray = array?.map((str, index) => ({ name: str, id: index + 1 }));
    setSelectedLeaveType(myArray)
    if(data.halfDay === true){
      setHalfDayChecked(true)
    }
  }
 
  let companyID = JSON.parse(localStorage.getItem('user'))?.company.id
  dispatch<any>(fetchUsersPublicHoliday(companyID))
},[dispatch,getUsersPublicHolidayList])

const convertDate = (inputDate) => {
  // Split the input date string (dd/mm/yyyy)
  const [day, month, year] = inputDate.split('/');
  // Create a new Date object (Note: month is 0-indexed, so subtract 1 from the month)
  const dateObject = new Date(year, month - 1, day);
  return dateObject
};
const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  return new Date(year, month - 1, day);  // Month is zero-indexed in JavaScript
};
const isDateDisabled = (date) => {
  return holidayList?.some(range => {
    const from = parseDate(range.fromDate);
    const to = parseDate(range.toDate);
    return date >= from && date <= to;
  });
};
const handleHolidayFromDate = (date) =>{
  if(date){
    if(formType === 'add'){
      if(data.halfDay === true){
        setData({...data,noOfDays: 0.5,fromDate : date})
      }
      else{
        let toDate = new Date(data?.toDate)
        const days = calculateDaysBetween(date ,toDate);
        if(leaveBalance < days){
          setErrMsg('Insufficient holiday balance')
        }
        else{
          setErrMsg('')
        }
        setData({...data,noOfDays: days,fromDate : date})
      }
    }
    else{
      let toDate = convertDate(data.toDate)
      const days = calculateDaysBetween(date,toDate);
      if(leaveBalance < days){
        setErrMsg('Insufficient holiday balance')
      }
      else{
        setErrMsg('')
      }
      setData({...data,noOfDays: days,fromDate : (moment(new Date(date)).format('DD/MM/YYYY'))})
    }
  }
  else{
    setData({...data,fromDate: null})
  }
   
}
 const handleHolidayToDate = (date) =>{
  if(date){
      if(formType === 'add'){
          let fromDate = new Date(data?.fromDate)
          const days = calculateDaysBetween(fromDate, date);
          if(leaveBalance < days){
            setErrMsg('Insufficient holiday balance')
          }
          else{
            setErrMsg('')
          }
        setData({...data, noOfDays: days,toDate : date})
    }
    else{
      let fromDate = convertDate(data.fromDate)
      const days = calculateDaysBetween(fromDate, date);
      if(leaveBalance < days){
        setErrMsg('Insufficient holiday balance')
      }
      else{
        setErrMsg('')
      }
    setData({...data, noOfDays: days,toDate : (moment(new Date(date)).format('DD/MM/YYYY'))})
    }
  }
  else{
    setData({...data,toDate: null})
  }
    
}

const onSubmit = (e) =>{
  e.preventDefault()
 let flag = false
  if(data?.fromDate === undefined){
    flag = true
    setFromDateValid(true)
  }
  if(data.halfDay === false && data?.toDate === undefined){
    flag = true
    setToDateValid(true)
  }
  if(errMsg !== ''){
    flag = true
  }
  if(!flag){
    if(formType === 'add'){
      let fromDate = (moment(new Date(data?.fromDate)).format('DD/MM/YYYY'));
      let toDate =  (moment (new Date(data?.toDate)).format('DD/MM/YYYY'));
      let obj = {
        user: {
          id: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
          },
        fromDate: fromDate,
        toDate: toDate,
        noOfDays: data?.noOfDays,
        halfDay : halfDayChecked,
        leaveType : selectedLeaveType[0]?.name,
      }
      let halfDayObj = {
        user: {
          id: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
          },
        fromDate: fromDate,
        toDate : fromDate,
        noOfDays: data?.noOfDays,
        halfDay : data?.halfDay,
        leaveType : selectedLeaveType[0]?.name,
        halfDayType: data?.halfDayType
      }
      dispatch<any>(addHoliday(halfDayChecked ? halfDayObj : obj));
   }
   else{
    let fromDate = data.fromDate;
    let toDate =  data.toDate;
    let obj = {
      user: {
        id: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        },
      fromDate: fromDate,
      toDate: toDate,
      noOfDays: data?.noOfDays,
      leaveType :  selectedLeaveType[0]?.name,
      halfDay : halfDayChecked,
      id : data['id']
    }
    let halfDayObj = {
      user: {
        id: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        },
      fromDate: fromDate,
      toDate : fromDate,
      noOfDays: data?.noOfDays,
      halfDay : data?.halfDay,
      leaveType : selectedLeaveType[0]?.name,
      halfDayType: data?.halfDayType
    }
    dispatch<any>(editHoliday(halfDayChecked ? halfDayObj : obj));
  }
  }
}
 // Convert date strings (dd/mm/yyyy) from data list to Date objects
 const disabledDates = usersPublicHolidayList.map(item => {
  const [day, month, year] = item.date.split('/');
  return new Date(`${year}-${month}-${day}`);
});
// Helper function to remove time from date objects
const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
  // Filter function to disable the dates
  const isDateDisabledPublicHoliday = (date) => {
    const strippedDate = stripTime(date); // Remove time from the date being checked

  return disabledDates.some(disabledDate => {
    const strippedDisabledDate = stripTime(disabledDate); // Remove time from the public holiday date
    return strippedDisabledDate.getTime() === strippedDate.getTime();
  });
  };

// Convert the public holiday list to a list of Date objects
const publicHolidayDates = usersPublicHolidayList.map(holiday => {
  const [day, month, year] = holiday.date.split('/');
  return new Date(`${year}-${month}-${day}`);
});

function isPublicHoliday(date) {
  return publicHolidayDates.some(holiday => 
    holiday.getDate() === date.getDate() &&
    holiday.getMonth() === date.getMonth() &&
    holiday.getFullYear() === date.getFullYear()
  );
}
const calculateDaysBetween = (start, end) => {
  if (!start || !end) return 0;
    let count = 0;
    let currentDate = new Date(start);
    while (currentDate <= end) {
    const dayOfWeek = currentDate.getDay();
    // Check if the day is not a weekend and not a public holiday
    if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isPublicHoliday(currentDate)) {
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return count;
};

const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;  // Disable Sundays (0) and Saturdays (6)
  };

// Combined filter function to disable weekends and date ranges
const filterDate = (date) => {
  return isWeekday(date) && !isDateDisabled(date)  && !isDateDisabledPublicHoliday(date);  // Disable if weekend or in dateRanges
};
const handleHalfDay = (e) => {
  setHalfDayChecked(e.target.checked)
  if(e.target.checked === true){
    setData({...data,halfDay: true, noOfDays: '0.5',halfDayType: selectedHalfDayType[0].name})
  }
  else{
    setData({...data,halfDay: false,noOfDays: ''})
  }
}
const handleHalfDayTypeChange = (e) =>{
  if(e.length === 0){
    setSelectedHalfDayType(e)
    setData({...data,halfDayType: null})
  }
  else{
    setSelectedHalfDayType(e)
    setData({...data,halfDayType: e[0].name})
  }
}
const handleLeaveTypeChange = (e) =>{
  if(e.length === 0){
    setSelectedLeaveType(e)
    setData({...data,leaveType: null})
  }
  else{
    setSelectedLeaveType(e)
    setData({...data,leaveType: e[0].name})
  }
}
return (
    <Fragment>
       <Form  onSubmit={onSubmit}>
            <Row>
              <Col>
               <FormGroup>
                  <Label for="examplePassword">
                      Leave type
                  </Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    className='dropdownComp'
                    labelKey="name"
                    onChange={handleLeaveTypeChange}
                    options={[{id: 1,name: 'PL'},{id: 2, name: 'SICK_LEAVE'}]}
                    clearButton
                    placeholder="Select leave type"
                    selected={selectedLeaveType}
                  />
                  {/* { companyValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                    Please select user's company
                  </span>} */}
               </FormGroup>
               </Col>
               <Col style={{paddingTop: '30px'}}>
                  <FormGroup checkinline>
                        <Input type="checkbox" checked={data.halfDay } onChange={(e) => handleHalfDay(e)}/>
                          <Label check>
                            Apply for half day
                          </Label>
                  </FormGroup>
              </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="examplePassword">
                        From Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                        </Label>
                        <DatePicker 
                        locale="en-gb"
                        selected={data?.fromDate && new Date((moment(data?.fromDate, 'DD/MM/YYYY').format()))} 
                        isClearable
                        filterDate={filterDate}
                        onChange={(date) => handleHolidayFromDate(date)} 
                        dateFormat="dd/MM/yyyy"/>
                        { fromDateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                            Please select date
                        </span>}
                    </FormGroup>
                </Col>
                {halfDayChecked === false || data.halfDay === false ?
                 <Col>
                  <FormGroup>
                      <Label for="examplePassword">
                         To Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                      </Label>
                      <DatePicker 
                        id='date'
                        locale="en-gb"
                        selected={data?.toDate && new Date((moment(data?.toDate, 'DD/MM/YYYY').format()))} 
                        minDate={data?.fromDate}
                        filterDate={filterDate}
                        isClearable
                        onChange={(date) => handleHolidayToDate(date)} 
                        dateFormat="dd/MM/yyyy"/>
                        { toDateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                            Please select date
                        </span>}
                  </FormGroup>
               </Col>
               : 
               <Col>
               <FormGroup>
                  <Label for="examplePassword">
                      Select half day
                  </Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    className='dropdownComp'
                    labelKey="name"
                    onChange={handleHalfDayTypeChange}
                    options={[{id: 1,name: 'First half'},{id: 2, name: 'Second half'}]}
                    clearButton
                    placeholder="Select half day"
                    selected={selectedHalfDayType}
                  />
                  {/* { companyValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                    Please select user's company
                  </span>} */}
               </FormGroup>
               </Col>
                 }
               
            </Row>
            <Row><FormGroup>
                <Label for="examplePassword">
                 Number Of Days
                </Label>
                <Input
                        value = {data?.noOfDays !== 0 ? data.noOfDays: ''}
                        disabled={true}
                        id="exampleEmail"
                        name="name"
                        type="number"
                        />
            </FormGroup></Row>
             { errMsg !== '' &&
              <li  style={{'color':'red'}}>{errMsg}</li>
              }
            <hr />
            <div className='createProjectBtn'>
              <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
              <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
         </Form>
    </Fragment>
  )
}

