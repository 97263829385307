import React, { Fragment, useEffect, useState } from 'react'
import { Button} from 'reactstrap'
import { FaCheckCircle  } from "react-icons/fa"
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom"
import './ConfirmComp.css'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { userVerification } from '../store/UserAction'


export const ConfirmComp: React.FC= () => {
const [confirmFlag, setConfirmFlag] = useState(false)
const navigate = useNavigate()
var   {uniqueId } = useParams()
const dispatch = useAppDispatch()
const userVerify = useAppSelector(state=>state.user.confirmUser)

useEffect(()=>{
  let obj = {
    uniqueId: uniqueId
  }
  dispatch<any>(userVerification(obj));
    setConfirmFlag(true)
},[dispatch])

const handleLogin = () =>{
    navigate('/login')
}
  return (
    <Fragment>
          <div className="confirm">
                     {confirmFlag === false
                        ? <div className="spinner-border spinner-border-sm loader" role="status">
                           <span className="sr-only">Loading</span>
                          </div>
                          : userVerify === true ?
                            <div>
                              <FaCheckCircle fontSize="100px" color="green"/>
                              <h6 className="label"> <b>Your account has been verified</b></h6>
                              <Button color="primary" onClick={handleLogin}>Go to login</Button>
                            </div>:
                            <Button color="primary" style={{marginTop: '8%'}} onClick={handleLogin}>Go to login</Button>
                       }
            </div>
    </Fragment>
  )
}
