import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Row, Col } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addCompany,  addPublicHoliday,  editCompany, editPublicHoliday} from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import './ManageUser.css'
import DatePicker, { registerLocale } from 'react-datepicker';
import { RiAddCircleFill } from "react-icons/ri";
import moment from 'moment';

type Props = {
    formType : string;
    rowData ?: any;
    addTableData ?: any;
    closeModal ?: any;
}
type FormDataProps ={
  name ?: any;
  date ?: any;
  company ?: any;
  id ?: any;
  optional : boolean;
}
export const AddPublicHoliday: React.FC<Props>  = ({formType,rowData,closeModal}: Props) =>  {
const [companyNameValid,setCompanyNameValid] = useState(false)
const [data, setData] =useState<FormDataProps>(rowData);
const loading = useAppSelector(state=>state.user.loading);
const dispatch=useAppDispatch()
const [selectedCompany,setSelectedCompany] =  useState([]) 
const company = useAppSelector(state=>state.user.companyList)
const [fields, setFields] = useState([{ company: {}, date: null, name: '' ,optional: false}]);
const [inputValidFlag, setInputFlag] = useState(true)
const [dateValid,setDateValid] = useState(false)
const [nameValid,setNameValid] = useState(false)


const isEmptyObject = (obj: object) => {
    return Object.keys(obj).length === 0;
};
const onSubmit = (e) =>{
  e.preventDefault()

 let flag = false
 const hasEmptyCompany = fields.some(field => isEmptyObject(field.company));
 const allFieldsValid = fields.every(holiday => (holiday.date !== '') || (holiday.name !== ''));
 if(formType === 'add' && hasEmptyCompany){
    flag = true
    setCompanyNameValid(true)
 }
  if(formType === 'add' && !allFieldsValid){
    flag = true
    setDateValid(true)
 }
 if(formType === 'edit' && data.company === ''){
    flag = true
    setCompanyNameValid(true)
 }
if(!flag){
    if(formType === 'add'){
        dispatch<any>(addPublicHoliday(fields));
    }
    else{
        let obj = {
            date: data.date,
            name : data.name,
            id : data.id,
            optional : data.optional
        }
      dispatch<any>(editPublicHoliday(obj));
    }
  }
}

const handleAddField = (a) => {
    if(a === 'add'){
        setFields([...fields, { company: { id: selectedCompany[0].id}, date: null, name: '', optional: false }]);

    }
    else{

    }
};

const handleDateChange = (date, index) => {
    const updatedFields = [...fields];
    updatedFields[index].date =  (moment(new Date(date)).format('DD/MM/YYYY'));
    setFields(updatedFields);
};

const handleInputChange = (e, index) => {
    const updatedFields = [...fields];
    updatedFields[index].name = e.target.value;
    setFields(updatedFields);
};
const handleOptionalHoliday = (e, index) =>{
    const updatedFields = [...fields];
    updatedFields[index].optional = e.target.checked;
    setFields(updatedFields);
}
const handleCompanyChange = (e) =>{
    if(e[0] !== undefined){
        if(formType === 'add'){
            setSelectedCompany(e)
            setInputFlag(false)
            setFields(prevFields =>
               prevFields.map(field => ({
                 ...field,
                 company: {id : e[0].id} // Set the selected company in each field
               }))
             );
        }
        else{
            setData({...data, company: e[0].name})
        }
   
    }
    else{
     setSelectedCompany([])
     setData({...data, company:''})
     setInputFlag(true)
    }
}
const handleEditInputChange = (e) =>{
   setData({...data, name: e.target.value})
}
const handleEditDateChange = (date) =>{
    if(date){
        let formatedDate = (moment(new Date(date)).format('DD/MM/YYYY'))
        setData({...data, date:formatedDate})
    }
    else{
        setData({...data, date:null})
    }
  
}
const handleOptionalHolidayOnEdit = (e) =>{
    setData({...data, optional: e.target.checked})
}
return (
    <Fragment>
       <Form  onSubmit={onSubmit}>
       <div>
           { formType === 'add' ?
             <>
                <Row>
                    <Col style={{maxWidth: '50%',paddingBottom: '20px'}}>
                        <Typeahead
                            className='dropdownComp'
                            id="basic-typeahead-single"
                            isInvalid={companyNameValid}
                            labelKey="name"
                            onChange={handleCompanyChange}
                            options={company}
                            clearButton
                            placeholder="Select company"
                            selected={selectedCompany}
                        />
                        { companyNameValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                        Please select company
                        </span>}
                    </Col>
                    <Col style={{float:'right'}}> <RiAddCircleFill  style={{width: '22px', height: '22px'}}onClick={ () =>handleAddField( inputValidFlag ? null :  'add')} /></Col>
                </Row>  
                <Row><Col>Date</Col><Col>Name of holiday</Col><Col></Col></Row>
                {fields.map((field, index) => (
                <div key={index}>
                    <Row>
                        <Col>
                            <FormGroup>
                                    <DatePicker
                                        selected={field.date && new Date((moment(field.date, 'DD/MM/YYYY').format()))}
                                        disabled = {inputValidFlag}
                                        isClearable
                                        placeholder = 'Select date'
                                        onChange={(date) => handleDateChange(date, index)}
                                        dateFormat="dd/MM/yyyy"
                                        style={{ marginRight: '10px' }}
                                    />
                                      { dateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                                       Please select date
                                     </span>}
                            </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                            <Input
                            type="text"
                            value={field.name}
                            disabled={inputValidFlag}
                            onChange={(e) => handleInputChange(e, index)}
                            placeholder="Enter text"
                            style={{ marginRight: '10px' }}
                            />
                        <FormFeedback>
                                Please enter company name
                            </FormFeedback>
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup checkinline>
                            <Input type="checkbox" disabled={inputValidFlag} checked={field.optional } onChange={(e) => handleOptionalHoliday(e,index)}/>
                                <Label check>
                                    Select as optional 
                                </Label>
                        </FormGroup>
                     </Col>
                  </Row>
                </div>
                ))}
            </> :
            <>
              <Row>
                    <Col style={{maxWidth: '50%',paddingBottom: '20px'}}>
                        <Typeahead
                            className='dropdownComp'
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={handleCompanyChange}
                            options={company}
                            clearButton
                            placeholder="Select company"
                            selected={[data.company]}
                        />
                    </Col>
              </Row>
              <Row>
                    <Col>
                        <FormGroup>
                            <Label>Date</Label>
                                    <DatePicker
                                        selected={data.date && new Date((moment(data.date, 'DD/MM/YYYY').format()))}
                                        isClearable
                                        placeholder = 'Select date'
                                        onChange={(date) => handleEditDateChange(date)}
                                        dateFormat="dd/MM/yyyy"
                                        style={{ marginRight: '10px' }}
                                    />
                            </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input
                            type="text"
                            value={data.name}
                            onChange={(e) => handleEditInputChange(e)}
                            placeholder="Enter text"
                            style={{ marginRight: '10px' }}
                            />
                        <FormFeedback>
                                Please enter holiday name
                            </FormFeedback>
                        </FormGroup>
                        </Col>
                        <Col style={{paddingTop: '27px'}}>
                        <FormGroup checkinline>
                            <Input type="checkbox"  checked={data.optional } onChange={(e) => handleOptionalHolidayOnEdit(e)}/>
                            <Label check>
                                Select optional holiday
                            </Label>
                        </FormGroup>
                     </Col>
             </Row>
            </>
           }
            <hr />
            <div className='createProjectBtn'>
             <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
             <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
        </div>
        </Form>
    </Fragment>
  )
}

