import React, { Fragment, useEffect, useState } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Collapse, ListItemIcon, Typography } from '@mui/material';
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import './WorklogList.css'
import { ModalComp } from '../pages/Modal';
import { AddWorklog } from '../pages/AddWorklog';
import { DeleteModalComp } from '../pages/DeleteModal';
import { deleteWorklog } from '../store/UserAction';
import { useAppDispatch ,useAppSelector} from '../hooks/redux-hooks';
import {ExpandLess} from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AddBreak } from '../pages/AddBreak';
import { fetchUsersProject} from '../store/UserAction';

type Props = {
  worklogList ?: any;
  closeModal ?: any;
  projectActive ?:any;
}

export const WorklogList: React.FC <Props>  = ({worklogList,projectActive}: Props) => {
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data,setData] = useState([])
    const [deletedTask, setDeletedTask] = useState([])
    const dispatch = useAppDispatch()
    const [showEditBreakModal, setShowEditBreakModal] = useState(false) 
 

const handleEditWorklog = (row) =>{
  setData(row)
  setShowEditModal(true)

}
const getTotals = (key) => {
  let totalHours = 0;
  let totalMinutes = 0;
  worklogList[key].forEach(item => {
    let replaceItem = item['timeSpent'] ;
    let time = replaceItem?.replace(':', '.')
    const [hours, minutes] = time?.split('.')?.map(parseFloat)
    totalHours += hours
    totalMinutes += minutes;
  });
  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;
  return `${totalHours?.toString().padStart(2, '0')}:${totalMinutes?.toString().padStart(2, '0')}`;
};
const handleEditBreakWorklog = (row) =>{
  setData(row)
  setShowEditBreakModal(true)
}
const closeModal = () =>{
    setShowEditModal(false)
    setShowDeleteModal(false)
    setShowEditBreakModal(false)
}
const handleDeleteWorklog =(row) =>{
    setDeletedTask(row)
    setShowDeleteModal(true)
}
const handleDeleteRow = () =>{
 let id = deletedTask ? deletedTask['id'] : 'null'
 dispatch<any>(deleteWorklog(id));
}

let formTemplate = <AddWorklog formType="edit"  rowData={data} closeModal={closeModal}/>
let BreakFormTemplate = <AddBreak formType="edit"  rowData={data} closeModal={closeModal}/>
  return (
    <Fragment>
          {showEditModal === true &&
             <ModalComp isOpen={showEditModal} modalTitle='Edit worklog details' id="addWorklog"   modalBody={formTemplate} toggle={closeModal}/>
          }
            {showEditBreakModal === true &&
             <ModalComp isOpen={showEditBreakModal} modalTitle='Edit break' id=""   modalBody={BreakFormTemplate} toggle={closeModal}/>
          }
         {showDeleteModal === true &&
             <DeleteModalComp isOpen={showDeleteModal} modalBody={'Are you sure you want to delete the value?'} toggle={closeModal} handleDelete={handleDeleteRow}/>
         }
  
        
        {Object.entries(worklogList).map(([key, array])=> (
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: '82%', flexShrink: 0 }}>
                {key}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 'small' }}>
              </Typography>
              <Typography sx={{ color: 'text.secondary',fontSize:'small' }}>{getTotals(key)}hrs</Typography>
           </AccordionSummary>
         { worklogList[key].map (el => 
          <AccordionDetails>
              <ListItem >
                  <ListItemText primary={el.taskName} />
                    <ListItemIcon style={{ cursor: 'pointer' }}>
                      <div className='text'>{el.description}</div>
                      <div style={{marginRight: ((el.project.isActive === false) && (key !== 'Break')) ? '78px' : '18px', fontSize:'small'}}>{el.timeSpent}hrs</div>
                      {((el.project.isActive === true) || (key === 'Break')) &&
                      <><RiPencilFill id='pencilIcon' onClick={key === 'Break' ? () => handleEditBreakWorklog(el) : () => handleEditWorklog(el)} /><MdDelete id='deleteIcon' onClick={() => handleDeleteWorklog(el)} /></>
                      }
                    
                    </ListItemIcon>
              </ListItem>
          </AccordionDetails>
         )}
       </Accordion>
  
      // <Accordion>
      //   <AccordionSummary
      //     expandIcon={<ExpandMoreIcon />}
      //     aria-controls="panel2-content"
      //     id="panel2-header"
      //   >
      //     Accordion 2
      //   </AccordionSummary>
      //   <AccordionDetails>
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
      //     malesuada lacus ex, sit amet blandit leo lobortis eget.
      //   </AccordionDetails>
      // </Accordion>
              /* //   <><ListItem button onClick={() => handleClick(worklogList[key][xyz].id)}>
              //   <ListItemText style={{ pointerEvents: 'none' }} primary={key} />
            
              //   {open ? <ExpandLess /> : <ExpandMore />}
              // </ListItem><Collapse in={open[worklogList[key][xyz].id]} timeout="auto" unmountOnExit>
              //     <List component="div" key={key} disablePadding>
              //       <ListItem button>
                   
              //         <ListItemText primary={worklogList[key][xyz].taskName} secondary={worklogList[key][xyz].timeSpent}  />
              //         <ListItemIcon style={{ cursor: 'pointer' }}>
              //     <RiPencilFill id='pencilIcon' onClick={() => handleEditWorklog(worklogList[key])} />
              //     <MdDelete id='deleteIcon' onClick={() => handleDeleteWorklog(worklogList[key])} />
              //   </ListItemIcon>
              //       </ListItem>
              //     </List>
              //   </Collapse></> */
            ))}

      </Fragment> )
}
    //   <ListItem>
    //     <ListItemAvatar>
    //       <Avatar>
    //         <ImageIcon />
    //       </Avatar>
    //     </ListItemAvatar>
    //     <ListItemText primary="Photos" secondary="Jan 9, 2014" />
    //   </ListItem>
    //   <ListItem>
    //     <ListItemAvatar>
    //       <Avatar>
    //         <WorkIcon />
    //       </Avatar>
    //     </ListItemAvatar>
    //     <ListItemText primary="Work" secondary="Jan 7, 2014" />
    //   </ListItem>
    //   <ListItem>
    //     <ListItemAvatar>
    //       <Avatar>
    //         <BeachAccessIcon />
    //       </Avatar>
    //     </ListItemAvatar>
    //     <ListItemText primary="Vacation" secondary="July 20, 2014" />
    //   </ListItem>
