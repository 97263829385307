import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, Row, Col, FormFeedback } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import enGb from 'date-fns/locale/en-GB';
import moment from "moment";
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addWorklog, editWorklog, fetchProject } from '../store/UserAction';
import '../components/Table/Table.css';

registerLocale('en-gb', enGb);

type Props = {
  formType : string;
  rowData ?: any;
  closeModal ?: any;
}
type FormDataProps ={
  name ?: any;
  taskName : string;
  date ?: any;
  timeSpent ?: any;
  id ?: any;
}
export const AddBreak: React.FC<Props>  = ({formType,rowData,closeModal}: Props) => {
  const [data, setData] =useState<FormDataProps>(rowData);
  const [dateValid, setDateValid] = useState(false)
  const [timeValid, setTimeValid] = useState(false)
  const loading = useAppSelector(state=>state.user.loading);
  const dispatch= useAppDispatch()

const onSubmit = (e) =>{
    e.preventDefault()
    let flag = false
   if((data?.timeSpent == '')){
     flag = true
     setTimeValid(true)
   }
   if((data?.date == null)){
    flag = true
    setDateValid(true)
  }
   if(!flag){
    if(formType === 'edit'){
      let editObj = {
        taskName : null,
        project : {
          id : 0
        },
       description: null,
       location: null,
       workLogType: "Non-Billable",
       date: data?.date,
       timeSpent: data?.timeSpent,
       id: data?.id
      }
      dispatch<any>(editWorklog(editObj));
    }
    else{
      let obj = {
        taskName : null,
        project : {
          id : 0
        },
        user: {
            id: JSON.parse(localStorage.getItem('user')).id
        },
       description: null,
       location: null,
       workLogType: "Non-Billable",
       date: data?.date,
       timeSpent: data?.timeSpent,
       breakType : 'Manual'
      }
      dispatch<any>(addWorklog(obj));
    }
   }
}
const handleDateChange = (date) =>{
    if(date) {
      setDateValid(false)
      let data1 = new Date(date)
      let newDate = data1 && data1.toLocaleDateString('en-GB')
      setData({...data , date: newDate})
    }
    else {
     setData({ ...data, date : null }); 
    }
}
const handleTime = (e) =>{
    if(e !== ''){
      setTimeValid(false)
    }
    setData({ ...data, timeSpent : e.target.value }); 
}
  return (
    <Fragment>
      <Form onSubmit={onSubmit}>
          <FormGroup>
               <Label for="examplePassword">
                  Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                 </Label>
                <DatePicker 
                id='date'
                locale="en-gb"
                selected={data?.date && new Date((moment(data?.date, 'DD/MM/YYYY').format()))} 
                isClearable
                onChange={(date) => handleDateChange(date)} 
                dateFormat="dd/MM/yyyy"/>
                { dateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                    Please select date
                 </span>}
                 
            </FormGroup>
            <FormGroup>
                <Label for="examplePassword">
                  Time Spent <span style={{color: '#A0A0A0'}}>(HH:MM)</span>
                </Label>
                <Input
                value = {data?.timeSpent}
                invalid={timeValid}
                onChange={handleTime}
                id="exampleEmail"
                name="name"
                placeholder="Enter Time Spent"
                type="time"
                />
                <FormFeedback>
                    Please enter time spent
                </FormFeedback>
            </FormGroup>
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
            
    </Form>
    </Fragment>

 )
}