import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Col } from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import './ConfirmComp.css'
import { resetPassword } from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { ToastContainer, toast } from 'react-toastify';

export const ResetPassword: React.FC= () => {
const [resetFlag, setResetFlag] = useState(false)
const [newPasswordValid, setNewPasswordValid] =useState(false)
const [confirmPasswordValid, setConfirmPasswordValid] =useState(false)
const [newPassword, setNewPassword] = useState();
const [confirmPassword, setConfirmPassword] = useState();
const [error,setErrorMsg] = useState('')
const navigate = useNavigate()
var   {ID } = useParams();
const dispatch = useAppDispatch()
const reset = useAppSelector(state=>state.user.resetPassword)
const resetPasswordError = useAppSelector(state=>state.user.errorResetPassword)
const loading = useAppSelector(state=>state.user.loading);

useEffect(()=>{
  if(reset === true){
    setResetFlag(true)
  }
  if(resetPasswordError !== ''){
    toast.error(`${resetPasswordError}`, {
      position: "top-right",
    });
  }
},[reset])
const handlePasswordChange = (e) =>{
 if(e.target.name === 'newPassword'){
    setNewPassword(e.target.value)
    setNewPasswordValid(false)
 }
 else{
    setConfirmPassword(e.target.value)
    setConfirmPasswordValid(false)
 }
}
const handleBack = () =>{
 navigate('/login')
}
const handleReset = (e) =>{
  e.preventDefault()
    let flag = false
    e.preventDefault()
    if(newPassword === undefined){
        flag = true
        setNewPasswordValid(true)
    }
    if(confirmPassword === undefined){
        flag = true
        setErrorMsg('Please confirm the new password')
        setConfirmPasswordValid(true)
    }
    if(newPassword !== confirmPassword){
        flag = true
        setErrorMsg('The new and the confirmation password do not match')
        setConfirmPasswordValid(true)
    }
    if(!flag){
      let obj = {
        uniqueId :ID,
        newPassword : newPassword
      }
      dispatch<any>(resetPassword(obj))
    }
}
  return (
    <Fragment>
       <ToastContainer />
        <Form onSubmit={handleReset}>
          <div className="d-flex h-100 justify-content-center align-items-center reset">
                        <Col md="4" className="mx-auto app-login-box">
                           <div className="modal-dialog w-100 mx-auto">
                              <div className="modal-content">
                                 <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                       <h4 className="mt-2">
                                         <img src={require("./reset-password-icon-8.jpg")}></img>
                                         <div>Reset password</div>
                                       </h4>
                                    </div>
                                    {resetFlag === false ?
                                    <div style={{padding: '20px'}}>
                                        <FormGroup>
                                            <Label for="exampleEmail">
                                             New password
                                            </Label>
                                            <Input
                                            invalid = {newPasswordValid}
                                            value={newPassword}
                                            id="exampleEmail"
                                            name="newPassword"
                                            type="password"
                                            onChange={handlePasswordChange}
                                            />
                                            <FormFeedback>
                                            Please enter new password
                                            </FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="exampleEmail">
                                            Re-enter the new password
                                            </Label>
                                            <Input
                                            invalid = {confirmPasswordValid}
                                            value={confirmPassword}
                                            id="exampleEmail"
                                            name="confirmPass"
                                            type="password"
                                            onChange={handlePasswordChange}
                                            />
                                            <FormFeedback>
                                             {error}
                                            </FormFeedback>
                                        </FormGroup>
                                    <hr />
                                    <div className="editUserBtn">
                                      <Button type='submit' color='primary'>{loading ? 'Resetting password...' : 'Reset password'}</Button>
                                    </div>
                                    </div> :
                                    <div className="backLogin">
                                     <Button color="primary" onClick={handleBack}>Go to login</Button></div>
                                    }
                                 </div>
                              </div>
                           </div>
                        </Col>
                      </div>
                      </Form>
    </Fragment>
  )
}
