import {createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../Service/UserService";
import { User } from "../Models/User";


export const login = createAsyncThunk(
   "login",
   async (data: any, thunkApi) => {
     try {
       const response : User[] = await UserService.login(data);
       localStorage.setItem('user', JSON.stringify(response));
       localStorage.setItem('token', JSON.stringify(response['token']));
       return response;
     } catch (error: any) {
       const message = error
       return thunkApi.rejectWithValue(message);
     }
   }
 );