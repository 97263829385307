import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addCompany,  editCompany} from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import './ManageUser.css'

type Props = {
    formType : string;
    rowData ?: any;
    addTableData ?: any;
    closeModal ?: any;
}
type FormDataProps ={
  name ?: any;
}
export const AddCompany: React.FC<Props>  = ({formType,rowData,closeModal}: Props) =>  {
const [companyNameValid,setCompanyNameValid] = useState(false)
const [data, setData] =useState<FormDataProps>(rowData);
const loading = useAppSelector(state=>state.user.loading);
const dispatch=useAppDispatch()

useEffect(()=>{

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 var re = /\S+@\S+\.\S+/;
 let flag = false
 if(data?.name === undefined){
  flag = true
  setCompanyNameValid(true)
}
if(!flag){
  // addTableData()
  if(formType === 'edit'){
    dispatch<any>(editCompany(data));
  }
  else{
    dispatch<any>(addCompany(data));
  }
}
}

const handleInputChange = (e) =>{
 const { name, value } = e.target;
 if(e.target.name === 'name' && e.target.value !== ''){
    setCompanyNameValid(false)
 }
 setData({...data,  [name]: value,})
}

return (
    <Fragment>
       <Form  onSubmit={onSubmit}>
            <FormGroup>
                <Label for="exampleEmail" style={{paddingBottom: '0.5rem'}}>
                 Company Name
                </Label>
                <Input
                invalid={companyNameValid}
                value={data?.name}
                id="exampleEmail"
                name="name"
                placeholder="Enter Company Name"
                type="text"
                onChange={handleInputChange}
                />
               <FormFeedback>
                    Please enter company name
                </FormFeedback>
            </FormGroup>
    
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}

