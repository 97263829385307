import React, { Fragment } from 'react'
import { Navbar } from '../components/Navbar'
import Table from '../components/Table/Table'


export const ManageWorklog: React.FC = () => {

  const role = () =>{

  }
  return (
    <Fragment>
        <Navbar/>
        
       <Table />
      {/* <button
        type="button"
        className="btn"
        cy-data="go-back-button"
        onClick={() => navigate('/')}
      >
        Go back
      </button> */}
    </Fragment>
  )
}
