import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, Row, Col, FormFeedback } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import enGb from 'date-fns/locale/en-GB';
import moment from "moment";
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addWorklog, editWorklog, fetchProject } from '../store/UserAction';
import '../components/Table/Table.css';

registerLocale('en-gb', enGb);

type Props = {
  formType : string;
  rowData ?: any;
  closeModal ?: any;
}
type FormDataProps ={
 
}
export const InfoTableComp: React.FC<Props>  = ({formType,rowData,closeModal}: Props) => {
  const [infoTableData, setData] =useState(rowData);

      function strToMins(t) {
        var s = t.split(":");
        return Number(s[0]) * 60 + Number(s[1]);
      }
      
      function minsToStr(t) {
        return Math.trunc(t / 60)+':'+('00' + t % 60).slice(-2);
      }
  return (
    <Fragment>
     <table className="inputTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Allocated Hours</th>
            <th>Logged Hours</th>
            <th>Remaining Hours</th>
          </tr>
        </thead>
        <tbody>
          {infoTableData.map(({ id, user, allocatedHrs, loggedHrs }) => (
            <tr key={id}>
              <td>
                {user.name}
              </td>
              <td>
                {allocatedHrs}
              </td>
              <td>
                {loggedHrs}
              </td>
              <td>
                {minsToStr( strToMins(allocatedHrs.includes(":00") ? `${allocatedHrs}` : allocatedHrs.concat(':00')) - strToMins(loggedHrs) )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>

 )
}