import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./UserSlice";
import loginSlice from "./LoginSlice";



const store=configureStore({
    reducer:{
        user: userSlice.reducer,
        login: loginSlice.reducer
    }
})

export type RootState =  ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
