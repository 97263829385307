import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './ProjectListComp.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import userSlice from '../../store/UserSlice';
import {fetchProjectList } from '../../store/UserAction'
import moment from 'moment';
import { format } from 'date-fns';

type Props = {
  onProjectSelection ?: any;
  onMenuClose ?: any;
  date ?: any;
}

export const ProjectListComp: React.FC<Props>  = ({onProjectSelection,onMenuClose,date}: Props)=> {
  const dispatch= useAppDispatch()
  const project = useAppSelector(state=>state.user.userProjectList)
  const getUserProjectFlag = useAppSelector(state=>state.user.getUserProjectFlag)
  const [projectList, setProjectList] = useState([])
  const selectRef = useRef(null)
  const [btnFlag,setBtnFlag] = useState(true)

  const onChange = checkedList => {
    if(checkedList.length !== 0 ){
      setBtnFlag(false)
    }
    else{
      setBtnFlag(true)
    }
    const res = checkedList.map(item => (item))
    onProjectSelection(res)
  }
  function dateSplit(dateString){
    const dateParts = dateString !== null && dateString?.split("/");
    return new Date(Date.UTC(dateParts[2], dateParts[1]-1, dateParts[0]))
  }
  useEffect(() =>{
    if(getUserProjectFlag){
      setProjectList(project)
    }
    const projectList  = project.filter(item => dateSplit(item?.endDate) >= dateSplit(date));
    const res =projectList.map(item => ({label: item.name,value : item.id }))
    dispatch<any>(fetchProjectList());
    setProjectList(res)
  },[dispatch,getUserProjectFlag,date])

  const onclick = () =>{
    onMenuClose(true)
  }

 const onBtnClick = () =>{
  dispatch(userSlice.actions.reset())
  onMenuClose(true)
  selectRef.current.state.isOpen = false
}

// const options = [
//   { label: 'Project 1', value: 1},
//   { label: 'Project 2', value: 2},
//   { label: 'Project 3', value: 3},
//   { label: 'Project 3', value: 4},
//   { label: 'Project 3', value: 5},
//   { label: 'Project 3', value: 6},
//   { label: 'Project 3', value: 7},
//   { label: 'Project 3', value: 8},
//   { label: 'Project 3', value: 9},
//   { label: 'Project 3', value: 10},
// ];

const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
  !isDisabled ? (
      <div ref={innerRef} {...innerProps}>
          {children}
          <button
              className="btn btn-info btn-sm btn-block"
              onClick={onBtnClick}
            >Apply</button>
      </div>
  ) : null;

  return (
    
    <Fragment>
        <ReactMultiSelectCheckboxes 
         ref={selectRef}
         options={projectList} components={{Menu:CustomMenu,IndicatorSeparator: () => null}}
         onChange={onChange} placeholderButtonLabel="Select Project" 
       
         >
        
            
         </ReactMultiSelectCheckboxes>
         {/* <MultiSelect {...props} /> */}
         {/* <Select
      closeMenuOnSelect={false}
      components={{ ValueContainer,MultiValue,Menu:CustomMenu,Option}}
      isMulti
      options={projectList}
      menuIsOpen={true}
    /> */}

    </Fragment>
  )
}
